import { Controller, useFormContext } from "react-hook-form";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import countries from "../../constants/countries.json";
import { useEffect } from "react";

const Base_URl = import.meta.env.VITE_APP_BASE_URL;

const Register = (props: any) => {
  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const { country: countryProp } = props;
  useEffect(() => {
    if (countryProp) setValue("region", countryProp);
  }, [setValue, countryProp]);
  const handleGoogleLogin = () => {
    localStorage.setItem("activeStep", `${1}`);
    localStorage.setItem("validationStep", `${1}`);
  };

  return (
    <div>
      <div className="onboarding-main-middle-create-account">
        <h2>Create your account</h2>
        <div className="inputWrapper">
          <Controller
            control={control}
            name="username"
            render={({ field }) => (
              <TextField
                id="username"
                label="username"
                placeholder="username"
                type="text"
                {...field}
                error={Boolean(errors?.username)}
                helperText={errors.username?.message?.toString()}
                autoComplete="off"
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextField
                id="email"
                label="email"
                placeholder="email"
                type="email"
                {...field}
                error={Boolean(errors?.email)}
                helperText={errors.email?.message?.toString()}
                autoComplete="off"
              />
            )}
          />
          <Controller
            control={control}
            name="region"
            render={({ field }) => (
              <TextField id="region" label="Region" select {...field} required>
                {countries.map((country, key) => {
                  return (
                    <MenuItem
                      key={key}
                      value={country.code}
                    >{`${country.name}  ${country.dial_code}`}</MenuItem>
                  );
                })}
              </TextField>
            )}
          />

          <TextField style={{ visibility: "hidden" }} />
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <TextField
                id="password"
                label="password"
                placeholder="password"
                type="password"
                {...field}
                error={Boolean(errors?.password)}
                helperText={errors.password?.message?.toString()}
                autoComplete="off"
              />
            )}
          />
          <Controller
            control={control}
            name="confirm_password"
            render={({ field }) => (
              <TextField
                id="confirm_password"
                label="confirm_password"
                placeholder="confirm password"
                type="password"
                {...field}
                error={Boolean(errors?.confirm_password)}
                helperText={errors?.confirm_password?.message?.toString()}
                autoComplete="off"
              />
            )}
          />
        </div>
      </div>
      <hr className="hr-text" data-content="OR" />
      <div className="onboarding-main-middle-or-section">
        <h2>Sign In Via</h2>
        <div>
          <a
            className="button button--social-login button--google"
            onClick={handleGoogleLogin}
            href={`${Base_URl}/auth/login?redirect_path=signup`}
          >
            <i className="icon fa-brands fa-google"></i>Login With Google
          </a>
          <a className="button button--social-login button--facebook" href="#">
            <i className="icon fa-brands fa-facebook"></i>Login With Facebook
          </a>
          <a className="button button--social-login button--linkedin" href="#">
            <i className="icon fa-brands fa-linkedin"></i>Login With Linkedin
          </a>
        </div>
      </div>
    </div>
  );
};

export default Register;
