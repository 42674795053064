import { RouteInterface } from "../interface/RouteInterface";
import Settings from "../page/settings";
import TeamSettings from "../page/settings/team";
import { lazy } from "react";
const Help = lazy(() => import("../components/userDetail/help/Help"));
const TeamContent = lazy(() => import("../page/teamContent/TeamContent"));
const TeamDesk = lazy(() => import("../page/teamDesk/TeamDesk"));
const Accounts = lazy(
  () => import("../components/userDetail/accounts/Accounts"),
);
const UserManagement = lazy(
  () => import("../components/userDetail/userManagement/UserManagement"),
);
const Notification = lazy(
  () => import("../components/userDetail/notification/Notification"),
);
const MyDesk = lazy(() => import("../page/myDesk/MyDesk"));

const UserDetail = lazy(() => import("./../components/userDetail/UserDetail"));
const NotFound = lazy(() => import("../page/not-found/NotFound"));

const MyContent = lazy(() => import("../page/myContent/MyContent"));

const DrivePage = lazy(() => import("../page/drivePage/DrivePage"));

const DropBoxPage = lazy(() => import("../page/dropBoxPage/DropBoxPage"));

const WhatsAppPage = lazy(() => import("../page/whatsAppPage/WhatsAppPage"));

const SharedWorkSpace = lazy(
  () => import("../page/sharedWorkSpace/SharedWorkSpace"),
);

const MySource = lazy(() => import("../page/mySource/MySource"));

const MyWorkSpace = lazy(() => import("../page/myWorkSpace/MyWorkSpace"));
const Calender = lazy(() => import("../page/calender/Calender"));
const Extension = lazy(() => import("../page/extension/Extension"));
const Gallery = lazy(() => import("../page/gallery/Gallery"));
const Mail = lazy(() => import("../page/mail/Mail"));

const PrivateRouteList: RouteInterface[] = [
  {
    path: "/my-source",
    name: "My Source",
    component: MySource,
  },
  {
    path: "/my-desk",
    name: "My Desk",
    component: MyDesk,
    children: [
      {
        path: "",
        component: MyContent,
        name: "mydesk/default",
      },
      {
        path: "my-content",
        component: MyContent,
        name: "mydesk/MyContent",
        subChildren: [
          {
            path: ":folder_id",
            component: MyContent,
            name: "My Content",
          },
        ],
      },
      {
        path: "drive",
        component: DrivePage,
        name: "mydesk/drive",
      },

      {
        path: "whatsApp",
        component: WhatsAppPage,
        name: "mydesk/WhatsApp",
      },
      {
        path: "mail",
        component: Mail,
        name: "mydesk/mail",
      },
      {
        path: "calender",
        component: Calender,
        name: "mydesk/calender",
      },
      {
        path: "extension",
        component: Extension,
        name: "mydesk/extension",
      },
      {
        path: "gallery",
        component: Gallery,
        name: "mydesk/gallery",
      },
      {
        path: "dropBox",
        component: DropBoxPage,
        name: "mydesk/dropBox",
      },
    ],
  },
  {
    path: "/my-workspace",
    name: "My Workspace",
    component: MyWorkSpace,
  },
  {
    path: "/shared-workspace",
    name: "Shared WorkSpace",
    component: SharedWorkSpace,
  },
  {
    path: "/team-desk",
    name: "Team Desk",
    component: TeamDesk,
  },
  {
    path: "/team-content",
    name: "Team Content",
    component: TeamContent,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    children: [
      {
        path: "sources",
        component: Accounts,
        name: "Sources",
      },
      {
        path: "permissions",
        component: Notification,
        name: "Permissions",
      },
      {
        path: "team",
        component: TeamSettings,
        name: "Team",
      },
    ],
  },
  {
    path: "/profile",
    name: "Profile",
    component: UserDetail,
    children: [
      {
        path: "notification",
        component: Notification,
        name: "Notification",
      },
      {
        path: "userManagement",
        component: UserManagement,
        name: "UserManagement",
      },
      {
        path: "accounts",
        component: Accounts,
        name: "Accounts",
      },
      {
        path: "help",
        component: Help,
        name: "Help",
      },
    ],
  },

  {
    path: "*",
    component: NotFound,
    name: "NotFound ",
  },
];
export default PrivateRouteList;
