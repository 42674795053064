import { extensionURlType } from "../../../interface/extensionUrlInterafce";
import {
  DELETE_EXTENSION_FILE,
  DELETE_EXTENSION_NOTE,
} from "./../../action/extensionAction/extensionActionType";

const initialState: {
  file_status: {};
  note_status: {};
} = {
  file_status: {},
  note_status: {},
};

export interface extensionType {
  type: "DELETE_EXTENSION_FILE" | "DELETE_EXTENSION_NOTE";
  payload: extensionURlType[];
}

const extensionReducer = (
  state = initialState,
  { type, payload }: extensionType,
) => {
  switch (type) {
    case DELETE_EXTENSION_FILE:
      return {
        ...state,
        file_status: payload,
      };
    case DELETE_EXTENSION_NOTE:
      return {
        note_status: payload,
      };

    default:
      return state;
  }
};

export default extensionReducer;
