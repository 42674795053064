import "./styles.css";

import * as yup from "yup";

import { useMemo, useState } from "react";

import { RootState } from "../../../redux/store/store";
import { addWhatsAppNumber } from "../../../graphql/mutation/addWhatsup";
import { clsx } from "clsx";
import countries from "../../../constants/countries.json";
import jwtDecode from "jwt-decode";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useMutation } from "@apollo/client";
import whatsapp from "../../../assets/logo-whatsapp.png";

const WHATSAPP_URL = import.meta.env.VITE_APP_WHATSAPP_URL;

const validationSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .test("phoneNumber", "Invalid number", (value) => {
      if (value) {
        return /^\d+$/.test(value) && value.length > 7; // Match only digits and length > 7
      }
      return true; // Allow empty value if not provided
    })
    .required("Phone number is required"),
});

const WhatsApp = ({ country }: any) => {
  const [number, setNumber] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [saveWhatsAppNo] = useMutation(addWhatsAppNumber);
  const user = JSON.parse(localStorage.getItem("tempUser") || "{}");
  const decoded: any = jwtDecode(user.access_token);

  function save() {
    const resp = validationSchema
      .validate({
        phoneNumber: number,
      })
      .then(() => {
        if (decoded?.user_id) {
          saveWhatsAppNo({
            variables: {
              user_id: decoded?.user_id,
              phone_number: Number(number),
            },
          });
          setSuccess(true);
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        console.log({ err });
        setError(true);
      });
  }

  async function handleChange(e: any) {
    setNumber(e.target.value);
    setError(false);
  }

  const code = useMemo(() => {
    const tem = countries.find((c) => {
      return c.code === country;
    });
    return tem?.dial_code;
  }, [country]);
  return (
    <div className={clsx("whatsapp-wrap", error && "error")}>
      <img src={whatsapp} />
      <span className="code">{code}</span>
      <input
        disabled={success}
        type="tel"
        name="number"
        placeholder="Enter Mobile Number"
        value={number}
        onChange={handleChange}
        required
      />
      <button disabled={success} onClick={save} type={"button"}>
        {success ? "connected" : "save"}
      </button>
    </div>
  );
};

export default WhatsApp;
