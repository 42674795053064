import "./SideNav.css";

import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { NavLink } from "react-router-dom";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import React from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SourceOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import _ from "lodash";
import easio from "../../assets/easio.png";
import logo from "../../assets/easio-logo.png";
import { logoutAction } from "./../../redux/action/authentication/authenticationAction";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useSelector } from "react-redux";

const SideBar = [
  {
    link: "/my-source",
    icon: <SourceOutlinedIcon />,
    name: "My Source",
  },
  {
    link: "/my-desk/my-content",
    icon: <HomeOutlinedIcon />,
    name: "My Desk",
  },
  {
    link: "/my-workspace",
    icon: <WorkOutlineOutlinedIcon />,
    name: "My Workspace",
  },
  {
    link: "/shared-workspace",
    icon: <PeopleAltOutlinedIcon />,
    name: "Shared WorkSpace",
    coming: true
  },
  {
    link: "/team-desk",
    icon: <GroupAddOutlinedIcon />,
    name: "Team Desk",
    coming: true
  },
  {
    link: "/team-content",
    icon: <FolderOutlinedIcon />,
    name: "Team Content",
    coming: true
  },
  {
    link: "/settings",
    icon: <SettingsOutlinedIcon />,
    name: "Settings",
    coming: true
  },
];

const SideNav: React.FC = () => {
  const active = useSelector(
    (state: { screenToggle: any }) => state.screenToggle,
  );
  const dispatch = useAppDispatch();

  const handleClick = (e: any, coming: boolean) => {
    if (coming)
      e.preventDefault()
  }

  return (
    <div className={active ? "navigation active" : "navigation"}>
      <ul>
        <li>
          <a>
            <span className="icon">
              <img src={logo} alt="logo" />
            </span>
            <span className="title">
              <img src={easio} alt="logo" />
            </span>
          </a>
        </li>

        {SideBar.map((item, index: number) => (
          <li key={index}>
            {
              item.coming ?
                <NavLink
                  onClick={(e) => handleClick(e, true)}
                  to={item.link}
                  className={({ isActive }) => (isActive ? "coming hovered" : "coming")}
                >
                  <span className="icon">{item.icon}</span>
                  <span className="title-coming">{"coming soon"}</span>
                </NavLink>
                :
                <NavLink
                  to={item.link}
                  className={({ isActive }) => (isActive ? "hovered" : "")}
                >
                  <span className="icon">{item.icon}</span>
                  <span className="title">{item.name}</span>
                </NavLink>
            }

          </li>
        ))}

        <li>
          <NavLink
            to={"/signout"}
            className={({ isActive }) => (isActive ? "hovered" : "")}
            onClick={() => dispatch(logoutAction() as any)}
          >
            <span className="icon">
              <ExitToAppOutlinedIcon></ExitToAppOutlinedIcon>
            </span>
            <span className="title">Sign Out</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default SideNav;
