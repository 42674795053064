import { useState, useEffect } from "react";
import axios from "axios";

const useFetch = (url: string, options = {}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | any>(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url, options);
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    const cacheData = localStorage.getItem(url);
    if (cacheData) {
      setData(JSON.parse(cacheData));
      setLoading(false);
    } else {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (data) {
      localStorage.setItem(url, JSON.stringify(data));
    }
  }, [data, url]);

  return { loading, error, data };
};

export default useFetch;
