import React, { useState } from "react";
import avtartImg from "../../assets/avtartImg.png";
import easioLogoFooter from "../../assets/easioLogoFooter.png";
import ssImg from "../../assets/easionImgSS.png";
import ssImg2 from "../../assets/easionImgSS2.png";
import ssImg3 from "../../assets/easionImgSS3.png";
import achivimg from "../../assets/achivimg.png";
import landingPageBanner from "../../assets/landingPageBanner.png";
import "./LandingPage.css";
import { Link } from "react-router-dom";
import easioLogo from "../../assets/easioLogo.svg";
import bannerIllustration from "../../assets/bannerIllustration.png";

const LandingPage = () => {
  const [click, setClick] = useState(false);
  return (
    <>
      <header>
        <nav className="logoNavWrap">
          <div className="start">
            <img src={easioLogo} alt="logo" />
          </div>
          <ul
            className={click ? "mobile-nav" : "navwrap "}
            onClick={() => setClick(false)}
          >
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/courses">Why Easio</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact">Contact Register</Link>
            </li>
            <li>
              <Link to="/signup"> Register</Link>
            </li>
            <li>
              <Link to="/login">Login</Link>
            </li>
          </ul>
          <button className="toggle" onClick={() => setClick(!click)}>
            {click ? (
              <i className="fa fa-times"> </i>
            ) : (
              <i className="fa fa-bars"></i>
            )}
          </button>
        </nav>
      </header>
      <section className="hero">
        <div className="heroTopBannerWrap">
          <img src={landingPageBanner} alt="img" />
        </div>
        <div className="heroTopBannerWrap_text_Illutration">
          <div className="heroContainer">
            <div className="heroRow">
              <h1 className="hedingTitle">Declutter. Organize. Search</h1>
              <p className="topLandingPagePara">
                The digital equivalent of having a well organised home. With
                Easio's hyper-personalized AI, you can efficiently organize and
                search content across teams and platforms.
              </p>
              {/* <h2 className="registerText">Register your interest</h2> */}
              <div className="inputRegWrap">
                <input type="text" placeholder="Your Email ID" />
                <button>Join the Waitlist</button>
              </div>
            </div>
          </div>
          <div className="bannerIllustration">
            <img src={bannerIllustration} alt="img" />
          </div>
        </div>
      </section>
      <section className="drivneSection">
        <div className="containerhomeAbout">
          <span className="subtitle">DRIVEN BY USERS</span>
          <h1 className="sectitle">A product built by users feedback</h1>
          <p className="drivenByUserPara">
            We heard you across the board, noted your concerns, limitations and
            need for a great product that helps you organise your content
          </p>
          <div className="grid2Crad">
            <div className="gridCradgreyBg">
              <div className="gridCrad">
                <p>
                  When you are searching for a specific document, you will find
                  every other document except the one required... it’s science
                  read it.{" "}
                </p>
                <div className="gridCradBottom">
                  <div className="avtartTextWrap">
                    <img src={avtartImg} alt="img" />
                    <div className="avtartText">
                      <h4>Kosha</h4>
                      <span>@Imkosha</span>
                    </div>
                  </div>
                  <div className="TimeViaWrap">
                    <span>10:03 AM | Dec 06, 2020</span>
                    <span>via Twitter for iPhone</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="gridCradgreyBg">
              <div className="gridCrad">
                <p>
                  When my son was born, i created an email address for him.
                  Every achievement and photo taken is sent to that email. When
                  he is old enough, he will get the password and will have all
                  those memories. Sharing this idea for those who may like it to
                  do as well.
                </p>
                <div className="gridCradBottom">
                  <div className="avtartTextWrap">
                    <img src={avtartImg} alt="img" />
                    <div className="avtartText">
                      <h4>Kosha</h4>
                      <span>@Imkosha</span>
                    </div>
                  </div>
                  <div className="TimeViaWrap">
                    <span>10:03 AM | Dec 06, 2020</span>
                    <span>via Twitter for iPhone</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="middleSection">
        <div className="readMoreBtnWrapp">
          <h1>How We Work</h1>
        </div>
        <div className="containerhomeAbout">
          <div className="grid2Crad">
            <div>
              <img className="ssImg" src={ssImg} alt="img" />
            </div>
            <div className="connectWrapp">
              <span className="subtitle">WHAT WE OFFER</span>
              <h1 className="sectitle">Connect with Easio</h1>
              <p className="connectPara">
                Import your documents from the leading data sources like Google
                Drive, One Drive, GMail, Facebook and more
              </p>
              <div className="checkTextWrap">
                <div className="checkParaDiv">
                  <i className="fas fa-check-circle"></i>
                  <p>One Click import</p>
                </div>
                <div className="checkParaDiv">
                  <i className="fas fa-check-circle"></i>
                  <p>Sort and organise at your personal desk</p>
                </div>
                <div className="checkParaDiv">
                  <i className="fas fa-check-circle"></i>
                  <p>Review file destinations in advance</p>
                </div>
              </div>
            </div>
          </div>
          <div className="grid2Crad">
            <div>
              <span className="subtitle">WHAT WE OFFER</span>
              <h1 className="sectitle">Auto Organise your files</h1>
              <p className="connectPara">
                Our proprietary AI understands your document needs over time and
                recommends the best way to organise your complete filing system
              </p>
              <div className="checkTextWrap">
                <div className="checkParaDiv">
                  <i className="fas fa-check-circle"></i>
                  <p>Automated recommendations</p>
                </div>
                <div className="checkParaDiv">
                  <i className="fas fa-check-circle"></i>
                  <p>Bulk actions on large volume files</p>
                </div>
                <div className="checkParaDiv">
                  <i className="fas fa-check-circle"></i>
                  <p>Consitent accuracy improvement on AI</p>
                </div>
              </div>
            </div>
            <div>
              <img className="ssImg" src={ssImg2} alt="img" />
            </div>
          </div>
          <div className="grid2Crad">
            <div>
              <img className="ssImg" src={ssImg3} alt="img" />
            </div>
            <div className="connectWrapp">
              <span className="subtitle">WHAT WE OFFER</span>
              <h1 className="sectitle">Superpowered search</h1>
              <p className="connectPara">
                Our detailed in-app search engine allows you to discover and
                identify your files across your sources and binders in a few
                seconds
              </p>
              <div className="checkTextWrap">
                <div className="checkParaDiv">
                  <i className="fas fa-check-circle"></i>
                  <p>Advanced Filters and sort</p>
                </div>
                <div className="checkParaDiv">
                  <i className="fas fa-check-circle"></i>
                  <p>Time period based refinement</p>
                </div>
                <div className="checkParaDiv">
                  <i className="fas fa-check-circle"></i>
                  <p>Accurate recommendations</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="achievementsSection">
        <div className="achievementsSection-bg-img-div">
          <img src={achivimg} alt="img" />
        </div>
        <div className="containerachievements ">
          <div className="achievementsleft ">
            <h1>Join the Waitlist</h1>
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor
            </span>
            <div className="inputRegWrap">
              <input type="text" placeholder="Your Email ID" />
              <button>Join the Waitlist</button>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="Footercontainer ">
          <div className="box logo">
            <img src={easioLogo} alt="img" />
            <p
              style={{ whiteSpace: "pre" }}
            >{`Hidden depth solutions pvt ltd\nSanyukt.sekhri@geteasio.com`}</p>
            <span>
              The world’s largest selection <br />
              of courses{" "}
            </span>
          </div>
          <div className="box link">
            <h3>Support</h3>
            <ul>
              <li>Contact Us</li>
            </ul>
          </div>
          <div className="box last">
            <h3>Company</h3>
            <ul>
              <li>About us</li>
              <li>Terms of use</li>
              <li>
                <Link to="/privacy">Privacy</Link>
              </li>
            </ul>
          </div>
          <div className="box link">
            <h3>Follow Us</h3>
            <div>
              <i className="fab fa-facebook-f icons"></i>
              <i className="fab fa-twitter icons"></i>
              <i className="fab fa-linkedin-in"></i>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>Copyright 2022.com, All rights reserved.</p>
        </div>
      </footer>
    </>
  );
};

export default LandingPage;
