import {
  UPDATE_SELECT_FILES,
  CLEAR_SELECT_FILES,
} from "../../action/selectuploadFiles/selectUploadFilesType";

interface selectionReducerType {
  type: "UPDATE_SELECT_FILES" | "CLEAR_SELECT_FILES";
  payload: any;
}

const initialState: { selectedFile: any } = { selectedFile: [] };

const selectUploadFilesReducer = (
  state = initialState,
  { type, payload }: selectionReducerType,
) => {
  switch (type) {
    case UPDATE_SELECT_FILES:
      return {
        selectedFile: payload,
      };
    case CLEAR_SELECT_FILES:
      return {
        selectedFile: payload,
      };
    default:
      return state;
  }
};

export default selectUploadFilesReducer;
