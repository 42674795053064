import {
  GOOGLE_G_DRIVE_IMPORT,
  GOOGLE_SERVICE_AUTH_URL,
  GOOGLE_STORE_TOKEN,
} from "../../action/otherService/otherServiceType";

export const initialState = {
  googleAuthURl: "",
  googleTokenStatus: {},
  gdriveStatus: {},
};

interface otherServiceReducerType {
  type:
    | "GOOGLE_G_DRIVE_IMPORT"
    | "GOOGLE_SERVICE_AUTH_URL"
    | "GOOGLE_STORE_TOKEN";
  payload: any;
}

const otherServiceReducer = (
  state = initialState,
  { type, payload }: otherServiceReducerType,
) => {
  switch (type) {
    case GOOGLE_SERVICE_AUTH_URL:
      return {
        ...state,
        googleAuthURl: payload,
      };
    case GOOGLE_STORE_TOKEN:
      return {
        ...state,
        googleTokenStatus: payload,
      };
    case GOOGLE_G_DRIVE_IMPORT:
      return {
        ...state,
        gdriveStatus: payload,
      };

    default:
      return state;
  }
};

export default otherServiceReducer;
