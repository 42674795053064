import { AddRounded } from "@mui/icons-material";
import { Button } from "@mui/material";

export const Toolbar = ({ page = "team" }) => {
  return (
    <div className="toolbar">
      <h2 className="setting-title">Settings</h2>
      {page === "team" && (
        <div className="team-toolbar">
          <div className="left" />
          <div className="team-workspace">workspace name</div>
          <div className="team-actions">
            <Button
              variant="contained"
              endIcon={<AddRounded />}
              className="bg-easio"
            >
              Add Member
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
