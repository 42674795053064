import {
  ADD_FILE_RECOMMENDATION,
  ADD_MULTIPLE_FILE_RECOMMENDATION,
  DELETE_RECOMMENDATION_ACCEPT_FILE,
  DELETE_RECOMMENDATION_FILE,
  RECOMMENDATION_DRAWER_BINDER,
  RECOMMENDATION_FILE_ACCEPT,
  SET_LOADING,
} from "./aiRecommendationType";
import {
  handleErrorWithToast,
  handleToastMsg,
} from "../../../helpers/toastMessage/toastMessage";

import AIService from "../../../services/aiService";
import { AppDispatch } from "../../store/store";
import { recommendationType } from "../../../interface/recommendationType";

// ADD All AI Files
const getRecommendationFile = (data: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch({
      type: RECOMMENDATION_FILE_ACCEPT,
      payload: data,
    });
  } catch (error) {
    handleErrorWithToast(error);
  }
};

// break All Ai file object
const RecommendationDrawer = (data: any) => (dispatch: AppDispatch) => {
  try {
    dispatch({
      type: RECOMMENDATION_DRAWER_BINDER,
      payload: data,
    });
  } catch (error) {
    handleErrorWithToast(error);
  }
};

const DeleteRecommendationFile = (data: number) => (dispatch: AppDispatch) => {
  try {
    dispatch({
      type: DELETE_RECOMMENDATION_FILE,
      payload: data,
    });
  } catch (error) {
    handleErrorWithToast(error);
  }
};

const DeleteRecommendationAcceptFile =
  (data: number | undefined) => async (dispatch: AppDispatch) => {
    try {
      await AIService.deleteRecommendation({ recommendation_id: data });
      dispatch({
        type: DELETE_RECOMMENDATION_ACCEPT_FILE,
        payload: data,
      });
    } catch (error) {
      handleErrorWithToast(error);
    }
  };

const AddFileRecomendation = (data: number) => (dispatch: AppDispatch) => {
  try {
    dispatch({
      type: ADD_FILE_RECOMMENDATION,
      payload: data,
    });
  } catch (error) {
    handleErrorWithToast(error);
  }
};

// one time add multiple file from AI
const Add_MultipleFile = (data: number[]) => (dispatch: AppDispatch) => {
  try {
    dispatch({
      type: ADD_MULTIPLE_FILE_RECOMMENDATION,
      payload: data,
    });
  } catch (error) {
    handleErrorWithToast(error);
  }
};

// move Ai file
const Move_Recommendation_File =
  (data: recommendationType) => async (dispatch: AppDispatch) => {
    try {
      const res = await AIService.postRecommendation(data);
      if (res.status === 200) {
        return handleToastMsg("Files Move Successfully");
      }
    } catch (error) {
      handleErrorWithToast(error);
    }
  };

const setLoading = (status: boolean) => {
  return {
    type: SET_LOADING,
    payload: status,
  };
};

export {
  getRecommendationFile,
  setLoading,
  RecommendationDrawer,
  AddFileRecomendation,
  Add_MultipleFile,
  Move_Recommendation_File,
  DeleteRecommendationFile,
  DeleteRecommendationAcceptFile,
};
