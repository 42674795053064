import {
  handleErrorWithToast,
  handleToastMsg,
} from "../../../helpers/toastMessage/toastMessage";
import GoogleService from "../../../services/googleService";
import { AppDispatch } from "../../store/store";
import {
  GOOGLE_G_DRIVE_IMPORT,
  GOOGLE_SERVICE_AUTH_URL,
  GOOGLE_STORE_TOKEN,
} from "./otherServiceType";

interface queryDataType {
  state: string;
  code: string;
  scope: string;
  redirect_path?: string;
}

export const googleServiceAuthLink =
  (url: string) => async (dispatch: AppDispatch) => {
    try {
      const res = await GoogleService.get_auth_url(url);
      dispatch({
        type: GOOGLE_SERVICE_AUTH_URL,
        payload: res.data,
      });
      return Promise.resolve(res.data);
    } catch (error: any) {
      handleErrorWithToast(error);
      return Promise.reject(error);
    }
  };

export const googleStoreToken =
  (queryData: queryDataType) => async (dispatch: AppDispatch) => {
    try {
      const res = await GoogleService.store_token(queryData);
      dispatch({
        type: GOOGLE_STORE_TOKEN,
        payload: res.data,
      });
      handleToastMsg(res.data.status);
      return Promise.resolve(res.data);
    } catch (error: any) {
      handleErrorWithToast(error);
      return Promise.reject(error);
    }
  };

export const gdriveImport = () => async (dispatch: AppDispatch) => {
  try {
    const res = await GoogleService.drive_import();
    dispatch({
      type: GOOGLE_G_DRIVE_IMPORT,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (error) {
    handleErrorWithToast(error);
    return Promise.reject(error);
  }
};
