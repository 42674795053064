export function identifierServiceConnet(data: string) {
  localStorage.setItem("service", data);
}

export function revmoveIdentifierServiceConnet(data: string) {
  localStorage.removeItem(data);
}

export function getLocalStorage(item: string) {
  const jwtJson = localStorage.getItem(item);
  const token = jwtJson ? JSON.parse(jwtJson) : "";
  return token;
}

export function storeUserData(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getUserData(key: string) {
  const data = localStorage.getItem(key);
  return data !== null ? JSON.parse(data) : null;
}
