export const SELECT_NOTE = "SELECT_NOTE";
export const SELECT_EMAIL = "SELECT_EMAIL";
export const SELECT_LOCATION = "SELECT_LOCATION";
export const SELECT_LINK = "SELECT_LINK";
export const SELECT_ATTACHMENT = "SELECT_ATTACHMENT";
export const SELECT_DATA = "SELECT_DATA";
export const SELECT_URL_NOTE = "SELECT_URL_NOTE";
export const DISPLAY_DATA = "DISPLAY_DATA";
export const CLEAR_SELECT_DATA = "CLEAR_SELECT_DATA";

export const NOTE_DELETE = "NOTE_DELETE";
export const LOCATION_DELETE = "LOCATION_DELETE";
export const ATTACHMENT_DELETE = "ATTACHMENT_DELETE";
export const LINK_DELETE = "LINK_DELETE";
export const EMAIL_DELETE = "EMAIL_DELETE";
export const URL_NOTE_DELETE = "URL_NOTE_DELETE";

export const GET_BINDER_DATA = "GET_BINDER_DATA";
export const DELETE_BINDER_DATA = "DELETE_BINDER_DATA";
export const CLEAR_BINDER_DATA = "CLEAR_BINDER_DATA";
