import { toast } from "react-toastify";
import { ErrorType } from "../../interface/errorInterface";

export const handleErrorWithToast = (error?: any) => {
  if (error.response && error.response.data && error.response.data?.detail) {
    toast.error(error.response.data.detail);
  } else if (error.message || error.toString()) {
    toast.error(error.message);
  } else {
    toast.error("Sorry, Something went wrong");
  }
};

export const handleToastMsg = (msg: string) => {
  toast.success(msg);
};
