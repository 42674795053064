import { HttpClient } from "../helpers/httpClient";

const PATH = {
  ME: "/auth/users/me/",
  EDIT_PROFILE: "/auth/users/me/edit_profile",
};

class UserService {
  public static getInfo() {
    return HttpClient.get(PATH.ME);
  }
  public static editInfo(editData: any) {
    return HttpClient.post(PATH.EDIT_PROFILE, editData);
  }
}

export default UserService;
