import "./styles.css";

import { NavLink, useLocation } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";

function SettingsSidebar() {
  // const [active, setActive] = useState("");

  const Location = useLocation();

  const active = useMemo(() => {
    switch (Location.pathname) {
      case "/settings/sources":
        return "sources";
      case "/settings/permissions":
        return "permissions";
      case "/settings/team":
        return "team";
      default:
        return "account";
    }
  }, [Location.pathname]);

  const subRoutes = [
    {
      name: "account",
      title: "My Account",
      icon: "fa fa-user",
      path: "/settings",
    },
    {
      name: "sources",
      title: "Sources",
      icon: "fa fa-file",
      path: "/settings/sources",
    },
    {
      name: "permissions",
      title: "Permissions",
      icon: "fa fa-key",
      path: "/settings/permissions",
    },
    {
      name: "team",
      title: "Team",
      icon: "fa fa-users",
      path: "/settings/team",
    },
  ];
  return (
    <>
      <div className="settings-side">
        {subRoutes.map((rt, key) => {
          return (
            <div key={key} className={`${active == rt.name ? "active" : ""}`}>
              <NavLink
                to={rt.path}
                className={`${active == rt.name ? "Hoverd" : ""}`}
              >
                <i
                  className={`${rt.icon} ${active == rt.name ? "Hoverd" : ""}`}
                />
                {rt.title}
              </NavLink>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default SettingsSidebar;
