export const RECOMMENDATION_FILE_ACCEPT = "RECOMMENDATION_FILE_ACCEPT";
export const RECOMMENDATION_FILE_ERROR = "RECOMMENDATION_FILE_ERROR";
export const RECOMMENDATION_FILE_REJECT = "RECOMMENDATION_FILE_REJECT";
export const RECOMMENDATION_DRAWER_BINDER = "RECOMMENDATION_DRAWER_BINDER";
export const ADD_FILE_RECOMMENDATION = "ADD_FILE_RECOMMENDATION";
export const ADD_MULTIPLE_FILE_RECOMMENDATION =
  "ADD_MULTIPLE_FILE_RECOMMENDATION";
export const DELETE_RECOMMENDATION_FILE = "DELETE_RECOMMENDATION_FILE";
export const DELETE_RECOMMENDATION_ACCEPT_FILE =
  "DELETE_RECOMMENDATION_ACCEPT_FILE";
export const MOVE_RECOMMENDATION_FILE = "MOVE_RECOMMENDATION_FILE";
export const SET_LOADING = "SET_LOADING";
