import { HttpClient } from "../helpers/httpClient";
import { recommendationType } from "../interface/recommendationType";

const PATH = {
  AI_RECOMMENDATION: "/recommendations/list_recommendations",
  ACCEPT_RECOMMENDATION: "/recommendations/accept_recommendation",
  DELETE_RECOMMENDATION: "/recommendations/delete_recommendation",
  GENERATE_RECOMMENDATION: "/recommendations/generate_recommendations",
};

class AIService {
  public static getRecommandation() {
    return HttpClient.get(PATH.AI_RECOMMENDATION);
  }

  public static genrateRecommandation() {
    return HttpClient.get(PATH.GENERATE_RECOMMENDATION);
  }

  public static postRecommendation(data: recommendationType) {
    return HttpClient.post(PATH.ACCEPT_RECOMMENDATION, data);
  }

  public static deleteRecommendation(data: {
    recommendation_id: number | undefined;
  }) {
    return HttpClient.post(PATH.DELETE_RECOMMENDATION, data);
  }
}

export default AIService;
