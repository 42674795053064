import "./App.css";

import { ApolloProvider } from "@apollo/client";
import AppRoutes from "./routes/AppRoutes";
import { DndProvider } from "react-dnd";
import FieldDragLayer from "./components/DragLayer/ItemsDragLayer";
import { HTML5Backend } from "react-dnd-html5-backend";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { WsReduxWrapper } from "./page/wsReduxWrapper";
import myStore from "./redux/store/store";
import { persistor } from "./redux/store/store";
import { useApollo } from "./useApollo";
import { useEffect } from "react";

const App = () => {
  const { client } = useApollo();

  return (
    <Provider store={myStore}>
      <PersistGate loading={null} persistor={persistor}>
        <DndProvider backend={HTML5Backend}>
          <FieldDragLayer />
          <ApolloProvider client={client}>
            <WsReduxWrapper>
              <AppRoutes />
            </WsReduxWrapper>
          </ApolloProvider>
        </DndProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
