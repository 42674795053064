import { MenuItem, OutlinedInput } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { AntSwitch } from "../../../../components/SwitchButtons";

export const MemberDetail = () => {
  return (
    <div className="detail-container">
      <div className="detail-title">Account/Sharing permissions</div>

      <div className="switch-container permissions">
        <div className="hori-wrap">
          <div className="col-block first">
            <div className="switch-item">
              <label>Make admin</label>
              <AntSwitch />
            </div>
            <div className="switch-item">
              <label>Source added</label>
              <AntSwitch />
            </div>
            <div className="switch-item">
              <label>File added</label>
              <AntSwitch />
            </div>
            <div className="switch-item">
              <label>File edited</label>
              <AntSwitch />
            </div>
          </div>
          <div className="col-block second">
            <div className="switch-item">
              <label>Desk</label>
              <AntSwitch />
            </div>
            <div className="switch-item">
              <label>Team Desk</label>
              <AntSwitch />
            </div>
            <div className="switch-item">
              <label>Binder</label>
              <AntSwitch />
            </div>
            <div className="switch-item">
              <label>File</label>
              <AntSwitch />
            </div>
          </div>
          <div className="col-block select-wrap">
            <Select input={<OutlinedInput />} fullWidth defaultValue={"name"}>
              <MenuItem key={"name"} value={"name"}>
                {"Drawers"}
              </MenuItem>
            </Select>
          </div>
        </div>
      </div>
      <div className="divider" />
      <div className="switch-container workspace">
        <div className="workspace-title">Shared workspace</div>
        <div className="hori-wrap">
          <div className="col-block first">
            <div className="switch-item">
              <label>Make admin</label>
              <AntSwitch />
            </div>
            <div className="switch-item">
              <label>Source added</label>
              <AntSwitch />
            </div>
            <div className="switch-item">
              <label>File added</label>
              <AntSwitch />
            </div>
          </div>
          <div className="col-block second">
            <div className="switch-item">
              <label>Desk</label>
              <AntSwitch />
            </div>
            <div className="switch-item">
              <label>Team Desk</label>
              <AntSwitch />
            </div>
            <div className="switch-item">
              <label>Binder</label>
              <AntSwitch />
            </div>
          </div>
          <div className="col-block">
            <div className="switch-item">
              <label>Desk</label>
              <AntSwitch />
            </div>
            <div className="switch-item">
              <label>Team Desk</label>
              <AntSwitch />
            </div>
            <div className="switch-item">
              <label>Binder</label>
              <AntSwitch />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
