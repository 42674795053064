import {
  ALLBINDERS,
  WIP,
  ARCHIVE,
  VAULT,
  CLEAR,
} from "../../action/drawerOption/drawerOptionType";

const initialState: { selectOPtion: string } = { selectOPtion: "ALLBINDERS" };

interface drawerOptionReducerType {
  type: string;
  payload: string;
}

const drawerOptionReducer = (
  state = initialState,
  { type, payload }: drawerOptionReducerType,
) => {
  switch (type) {
    case ALLBINDERS:
      return { selectOPtion: payload };
    case WIP:
      return { selectOPtion: payload };
    case ARCHIVE:
      return { selectOPtion: payload };
    case VAULT:
      return { selectOPtion: payload };
    case CLEAR:
      return { selectOPtion: "" };

    default:
      return state;
  }
};

export default drawerOptionReducer;
