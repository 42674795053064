import { applyMiddleware, legacy_createStore as createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";

import allReducers from "../reducer/index";
import { composeWithDevTools } from "@redux-devtools/extension";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunkMiddleware from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, allReducers);

const myStore = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware)),
);

export const persistor = persistStore(myStore);

export default myStore;

export type RootState = ReturnType<typeof myStore.getState>;
export type AppDispatch = typeof myStore.dispatch;
export type RootStore = ReturnType<typeof allReducers>;
