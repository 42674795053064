import { handleErrorWithToast } from "../../../helpers/toastMessage/toastMessage";
import { userinfoInterface } from "../../../interface/userinfoInterface";
import userService from "../../../services/userService";
import { AppDispatch } from "../../store/store";
import { USERINFO, EDITINFO } from "./userType";

export const addUserInfo = (userData: userinfoInterface) => {
  localStorage.setItem("info", JSON.stringify(userData));
  return {
    type: USERINFO,
    payload: userData,
  };
};

export const editUserInfo =
  (editInfo: any) => async (dispatch: AppDispatch) => {
    try {
      const res = await userService.editInfo(editInfo);
      dispatch({
        type: EDITINFO,
        payload: res.data,
      });
      return Promise.resolve(res.data);
    } catch (error) {
      handleErrorWithToast(error);
      return Promise.reject(error);
    }
  };
