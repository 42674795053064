import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
  split,
} from "@apollo/client";
import { useCallback, useEffect } from "react";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { getUserData } from "./helpers/localstorage/localStorage";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

const accessToken = getUserData("user")?.access_token;
const generateAuthorizationLink = (accessToken: string) =>
  setContext((_, { headers, ...context }) => ({
    headers: {
      ...headers,
      ...(accessToken ? { authorization: `Bearer ${accessToken}` } : {}),
    },
    ...context,
  }));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const getLink = (userToken = "") => {
  const HASURA_WEB_SOCKET = import.meta.env.VITE_APP_HASURA_WEB_SOCKET;
  const HASURA_HTTPS_URL = import.meta.env.VITE_APP_HASURA_HTTPS_URL;
  const wsLink = new GraphQLWsLink(
    createClient({
      url: HASURA_WEB_SOCKET,
      connectionParams: {
        reconnect: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: userToken ? `Bearer ${userToken}` : "",
        },
      },
    }),
  );

  const httpLink = createHttpLink({
    uri: HASURA_HTTPS_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: userToken ? `Bearer ${userToken}` : "",
    },
  });
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink,
  );
  return from([generateAuthorizationLink(userToken), errorLink, splitLink]);
};
const link = getLink(accessToken);
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});
export const useApollo = () => {
  const initClient = useCallback(() => {
    const accessToken = getUserData("user")?.access_token;
    const link = getLink(accessToken);
    client.setLink(link);
  }, []);

  useEffect(() => {
    window.addEventListener("storage", () => initClient());
    return () => {
      window.removeEventListener("storage", () => initClient());
    };
  }, [initClient]);

  return {
    client,
    initClient,
  };
};
