import { gql } from "@apollo/client";

export const GetUserDetail = gql`
  subscription MyQuery {
    users {
      dob
      dropbox_active
      email
      first_name
      gdrive_active
      gender
      gmail_active
      id
      last_name
      onboarding_completed
      phone_number
      picture
      root_folder
      whatsapp_active
      region
      username
    }
  }
`;
