import React, { Fragment } from "react";
import { fileType } from "../../interface/file-Interface";

const getFieldStyle = (isDragging: any) => {
  const style = {
    borderStyle: "dashed",
    borderWidth: 1,
    height: 30,
    margin: 5,
    backgroundColor: "pink",
    opacity: 1,
  };
  style.opacity = isDragging ? 0.5 : 1;
  return style;
};

const ItemsTemplate = (fields: { fields: fileType[] }) => {
  const count = fields.fields.length;
  const displayData = fields.fields[0];

  return (
    <div>
      {fields.fields.length > 0 && (
        <>
          <Fragment key={displayData.id}>
            {displayData.data_type === "url_object" ? (
              <div key={displayData.url} style={getFieldStyle(false)}>
                <div>
                  {displayData.url && displayData.url.length > 8
                    ? displayData.url.substring(0, 25) + "..."
                    : displayData.url}
                </div>

                {count}
              </div>
            ) : (
              <div key={displayData.name} style={getFieldStyle(false)}>
                <div>
                  {displayData.name && displayData.name.length > 8
                    ? displayData.name.substring(0, 25) + "..."
                    : displayData.name}
                </div>
                {count}
              </div>
            )}
          </Fragment>
        </>
      )}
    </div>
  );
};

export default ItemsTemplate;

// <Fragment key={item.id}>
//   {item.data_type === "url_object" ? (
//     <div key={item.url} style={getFieldStyle(false)}>
//       {item.url}
//     </div>
//   ) : (
//     <div key={item.name} style={getFieldStyle(false)}>
//       {item.name}
//     </div>
//   )}
// </Fragment>
