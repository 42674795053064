import axios from "axios";
import { AxiosRequestConfig } from "axios";

export const Base_URl = import.meta.env.VITE_APP_BASE_URL;

const HEADER = {
  "content-type": "application/json",
};

export const HttpClient = axios.create({
  baseURL: Base_URl,
  headers: { ...HEADER },
});

HttpClient.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const jwtJson = localStorage.getItem("user");
    const token = jwtJson ? JSON.parse(jwtJson) : "";
    if (token) {
      config.headers!.Authorization = `Bearer ${token.access_token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

HttpClient.interceptors.response.use(
  (res) => {
    return Promise.resolve(res);
  },
  (error) => {
    return Promise.reject(error);
  },
);

export function requestOption(method?: "GET", header?: {}, body?: {}) {
  const jwtJson = localStorage.getItem("user");
  const token = jwtJson ? JSON.parse(jwtJson) : "";
  const cutomeHeader = {
    "content-type": "application/json",
    Authorization: `Bearer ${token.access_token}`,
  };

  return {
    method: method,
    headers: header ? header : cutomeHeader,
    body: JSON.stringify(body),
  };
}
