import { gql } from "@apollo/client";

export const getAllRecommendation = gql`
  subscription MyQuery($user_id: Int!) {
    recommendations_view(where: { ignored: { _eq: false }, user_id: {_eq: $user_id}}, order_by: {id: desc}) {
      id
      object_count
      object_ids
      drawer_name_options
      binder_name_options
      tags
    }
  }
`;
