import AddFileToggleReducer from "./addFileToggleReducer/AddFileToggleReducer";
import FileReducer from "./fileReducer/fileReducer";
import addFileReducer from "./addFileReducer/addFileReducer";
import authenticateReducer from "./authenticateReducer/authenticateReducer";
import azureFileUploadReducer from "./azureFileUploadReducer/azureFileUploadReducer";
import { combineReducers } from "redux";
import drawerOptionReducer from "./drawerOptionReducer/drawerOptionReducer";
import drawerSearch_reducer from "./drawerSearch_reducer/drawerSearchReducer";
import extensionReducer from "./extensionReducer/extensionReducer";
import filterReducer from "./filterReducer/filterReducer";
import folderReducer from "./folderReducer/folderReducer";
import navigationFilterReducer from "./navigationFilterReducer/navigationFilterReducer";
import otherServiceReducer from "./otherServiceReducer/otherServiceReducer";
import recommendation_reducer from "./aiRecommendation_reducer/aiRecommendation_reducer";
import reviewPageReducer from "./reviewPageReducer/reviewPageReducer";
import reviewReducer from "./reviewReducer/reviewReducer";
import screenToggle from "./screenToggle";
import searchItemReducer from "./searchItemReducer/searchItemReducer";
import selectUploadFilesReducer from "./selectUploadFilesReducer/selectUploadFilesReducer";
import uploadedReducer from "./uploadedFilesReducer/uploadedFilesReducer";
import userReducer from "./userReducer/userReducer";

const allReducers = combineReducers({
  screenToggle,
  authenticateReducer,
  userReducer,
  extensionReducer,
  reviewReducer,
  azureFileUploadReducer,
  uploadedReducer,
  AddFileToggleReducer,
  selectUploadFilesReducer,
  folderReducer,
  addFileReducer,
  FileReducer,
  navigationFilterReducer,
  searchItemReducer,
  otherServiceReducer,
  drawerOptionReducer,
  filterReducer,
  reviewPageReducer,
  recommendation_reducer,
  drawerSearch_reducer,
});

export default allReducers;
