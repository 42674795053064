import LandingPage from "../page/landingPage/LandingPage";
import Login from "../page/auth/login/Login";
import Onboarding from "../components/onboarding/Onboarding";
import Privacy from "../page/privacy/Privacy";
import { RouteInterface } from "./../interface/RouteInterface";

const authRoute: RouteInterface[] = [
  {
    path: "/",
    component: LandingPage,
    name: "LandingPage",
  },
  {
    path: "/login",
    component: Login,
    name: "login",
  },
  {
    path: "/signup",
    component: Onboarding,
    name: "signup",
  },
  {
    path: "/signup-org",
    component: Onboarding,
    name: "signup-org",
  },
  {
    path: "/privacy",
    component: Privacy,
    name: "privacy",
  },
];

export default authRoute;
