import "./Login.css";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer, toast } from "react-toastify";
import {
  googleAction,
  loginAction,
} from "../../../redux/action/authentication/authenticationAction";

import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import googleSvg from "../../../assets/svg/google.svg";
import loginValidation from "../../../validations/loginValidation";
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "./../../../hooks/useAppDispatch";
import { useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "grey",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },

    "&.Mui-focused fieldset": {
      borderColor: "grey",
      border: "1px solid grey",
      borderRadius: "10px",
    },
  },
});

const Base_URl = import.meta.env.VITE_APP_BASE_URL;

const Login = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.has("access_token")) {
      const userInfo = {
        access_token: searchParams.get("access_token"),
        token_type: searchParams.get("token_type"),
        name: searchParams.get("name"),
        email: searchParams.get("email"),
        profile_picture: searchParams.get("profile_picture"),
      };
      dispatch(googleAction(userInfo) as any);
      navigate("/login");
    }
  }, [searchParams]);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: loginValidation,
    onSubmit: async (values) => {
      const data = {
        username: values.username.trim().toLowerCase(),
        password: values.password.trim(),
      };
      dispatch(loginAction(data) as any);
      formik.resetForm();
    },
  });

  return (
    <div className="main-signin-wrapper">
      <div className="wrapper">
        <ToastContainer autoClose={5000} />
        <form onSubmit={formik.handleSubmit}>
          <div className="headline">
            <h2>Log in to Easio</h2>
            <img src="/easio.png" />
          </div>

          <p className="description">
            Organize all your workspaces, powered by AI.
          </p>
          <div className="input-icon-wrap">
            <CssTextField
              autoComplete="off"
              sx={{ position: "relative", mb: 2 }}
              fullWidth
              placeholder="Email Address"
              id="email"
              name="username"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
              helperText={
                formik.touched.username && formik.errors.username
                  ? formik.errors.username
                  : "Enter username"
              }
              error={
                formik.touched.username && formik.errors.username ? true : false
              }
            />
            <i className="fas fa-user"></i>
          </div>
          <div className="input-icon-wrap">
            <CssTextField
              autoComplete="off"
              sx={{ position: "relative", mb: 2 }}
              fullWidth
              placeholder="Password"
              id="password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              helperText={
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : "Enter password"
              }
              error={
                formik.touched.password && formik.errors.password ? true : false
              }
            />

            <i className="fas fa-lock"></i>
          </div>
          <Button className="signBtn" variant="contained" type="submit">
            Sign in
          </Button>
          <div className="or-wrapper">
            <span className="or-txt">OR</span>
            <div className="dash" />
          </div>

          <a
            className="google-wrap"
            href={`${Base_URl}/auth/login?redirect_path=login`}
          >
            <span>Log In with</span>
            <img src={googleSvg} />
          </a>
        </form>
      </div>
    </div>
  );
};

export default Login;
