import {
  CLEAR_BINDER,
  DELETE_BINDER_FILES,
  DELETE_DRAWER_FILES,
  DELETE_FILES,
  GET_DRAWER,
  GET_FILES,
  ROUTE_NAME,
  SEARCH_FILES,
  SELECT_BINDER,
  SET_ACTIVE_FILTER,
  SET_LOADING,
} from "../../action/uploaded_files_list/uploaded_files_list_type";

import _ from "lodash";
import { fileType } from "./../../../interface/file-Interface";

const initialState: {
  activeFilter: string;
  files_Lists: { [key: string]: fileType[] };
  selectedFile: {};
  drawer_list: any[];
  binder_list: any[];
  loading: boolean;
  routeName?: string;
} = {
  activeFilter: "CURRENT_ALL",
  files_Lists: {
    CURRENT_ALL: [],
  },
  selectedFile: {},
  drawer_list: [],
  binder_list: [],
  loading: false,
  routeName: "",
};

export interface uploadedReducerPayloadType {
  type:
  | "DELETE_DRAWER_FILES"
  | "DELETE_FILES"
  | "GET_FILES"
  | "SEARCH_FILES"
  | "SELECT_BINDER"
  | "DELETE_BINDER_FILES"
  | "CLEAR_BINDER"
  | "GET_DRAWER"
  | "SET_LOADING"
  | "SET_ACTIVE_FILTER"
  | "ROUTE_NAME";

  payload: any | fileType[];
}

const uploadedReducer = (
  state = initialState,
  { type, payload }: uploadedReducerPayloadType,
) => {
  switch (type) {
    case GET_FILES:
      return {
        ...state,
        files_Lists: {
          ...state.files_Lists,
          [payload["type"]]: payload["fileData"],
        },
      };
    case SET_ACTIVE_FILTER:
      return {
        ...state,
        activeFilter: payload,
      };
    case GET_DRAWER:
      const drawerList = _.filter(
        payload,
        (obj: fileType) => obj.data_type === "drawer_object",
      );
      return {
        ...state,
        drawer_list: drawerList,
      };

    case DELETE_FILES:
      const deleteData = state.files_Lists[state.activeFilter].filter(
        (item) => item.id !== payload,
      );
      let newFileList = state.files_Lists;
      newFileList[state.activeFilter] = deleteData;
      return {
        ...state,
        files_Lists: { ...newFileList },
      };

    case SEARCH_FILES:
      return {
        ...state,
        files_Lists: payload,
      };

    case SELECT_BINDER:
      return {
        ...state,
        binder_list: payload,
      };

    case DELETE_DRAWER_FILES:
      const deleteDrawer = state.drawer_list.filter(
        (item) => item.id !== payload,
      );
      return {
        ...state,
        drawer_list: deleteDrawer,
      };

    case DELETE_BINDER_FILES:
      const deleteBinder = state.binder_list.filter(
        (item) => item.id !== payload,
      );
      return {
        ...state,
        binder_list: deleteBinder,
      };
    case CLEAR_BINDER:
      return {
        ...state,
        binder_list: [],
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ROUTE_NAME:
      return {
        ...state,
        routeName: payload,
      };

    default:
      return state;
  }
};

export default uploadedReducer;
