import loadernew from "../../assets/lodernew.svg";
const SetupFile = (props: { isloading: boolean }) => {
  const { isloading } = props;
  return (
    <div>
      {isloading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "40vh",
          }}
        >
          <img src={loadernew} alt="loder.." style={{ width: "6rem" }} />
        </div>
      )}
    </div>
  );
};

export default SetupFile;
