import { ADD_FILES } from "../../action/azureFileUpload/azureFileUploadType";

export const initialState: {
  uploadMsg: {};
} = {
  uploadMsg: {},
};

interface azureFileUploadReducer {
  type: "ADD_FILES";
  payload: any;
}

const azureFileUploadReducer = (
  state = initialState,
  { type, payload }: azureFileUploadReducer,
) => {
  switch (type) {
    case ADD_FILES:
      return {
        ...state,
        uploadMsg: payload,
      };

    default:
      return state;
  }
};

export default azureFileUploadReducer;
