import { fileType } from "./../../../interface/file-Interface";
import {
  WHATSAPP_SEARCH,
  DROPBOX_SEARCH,
  GOOGLE_SEARCH,
  DELETE_GOOGLE_SEARCH_ITEM,
  DELETE_DROPBOX_SEARCH_ITEM,
  DELETE_WHATSAPP_SEARCH_ITEM,
  EXTENSION_SEARCH,
  DELETE_EXTENSION_SEARCH_ITEM,
  SET_LOADING,
} from "../../action/searchItem/searchItemType";

interface searchItemReducerType {
  type:
    | "WHATSAPP_SEARCH"
    | "DROPBOX_SEARCH"
    | "GOOGLE_SEARCH"
    | "DELETE_GOOGLE_SEARCH_ITEM"
    | "DELETE_DROPBOX_SEARCH_ITEM"
    | "DELETE_WHATSAPP_SEARCH_ITEM"
    | "EXTENSION_SEARCH"
    | "DELETE_EXTENSION_SEARCH_ITEM"
    | "SET_LOADING";
  payload: any | fileType[];
}

const initialState: {
  googleItem: fileType[];
  whatsappItem: fileType[];
  dropboxItem: fileType[];
  extensionItem: fileType[];
  isLoading: boolean;
} = {
  googleItem: [],
  whatsappItem: [],
  dropboxItem: [],
  extensionItem: [],
  isLoading: false,
};

const searchItemReducer = (
  state = initialState,
  { type, payload }: searchItemReducerType,
) => {
  switch (type) {
    case WHATSAPP_SEARCH:
      return {
        ...state,
        whatsappItem: payload,
      };
    case DROPBOX_SEARCH:
      return {
        ...state,
        dropboxItem: payload,
      };

    case GOOGLE_SEARCH:
      return {
        ...state,
        googleItem: payload,
      };

    case EXTENSION_SEARCH:
      return {
        ...state,
        extensionItem: payload,
      };

    case DELETE_WHATSAPP_SEARCH_ITEM:
      const Data = state.whatsappItem.filter(
        (item: any) => item.id !== payload,
      );
      return {
        ...state,
        whatsappItem: Data,
      };

    case DELETE_EXTENSION_SEARCH_ITEM:
      const deleteData = state.extensionItem.filter(
        (item: any) => item.id !== payload,
      );
      return {
        ...state,
        extensionItem: deleteData,
      };

    case DELETE_GOOGLE_SEARCH_ITEM:
      const deletedData = state.googleItem.filter(
        (item: any) => item.id !== payload,
      );
      return {
        ...state,
        googleItem: deletedData,
      };

    case DELETE_DROPBOX_SEARCH_ITEM:
      const deleted_Data = state.dropboxItem.filter(
        (item: any) => item.id !== payload,
      );
      return {
        ...state,
        dropboxItem: deleted_Data,
      };

    case SET_LOADING:
      return {
        ...state,
        isloading: payload,
      };

    default:
      return state;
  }
};

export default searchItemReducer;
