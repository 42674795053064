import {
  DELETE_FILE,
  CREATE_FILE,
  DOWNLOAD_FILE,
} from "../../action/file/fileType";

const initialState: {
  create_file_status: {};
  delete_file_status: {};
  download_file_status: {};
} = {
  create_file_status: {},
  delete_file_status: {},
  download_file_status: {},
};

interface FileReducerType {
  type: "DELETE_FILE" | "CREATE_FILE" | "DOWNLOAD_FILE";
  payload: string;
}

const FileReducer = (
  state = initialState,
  { type, payload }: FileReducerType,
) => {
  switch (type) {
    case CREATE_FILE:
      return {
        ...state,
        create_file_status: payload,
      };
    case DELETE_FILE:
      return {
        ...state,
        delete_file_status: payload,
      };
    case DOWNLOAD_FILE:
      return {
        ...state,
        download_file_status: payload,
      };
    default:
      return state;
  }
};

export default FileReducer;
