import "./Onboarding.css";

import { FormProvider, useForm } from "react-hook-form";
import { ValidationError, boolean, object, ref, string } from "yup";
import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import CreateProfile from "./CreateProfile";
import LinkAccount from "./LinkAccounts";
import Register from "./Register";
import SetupFile from "./SetupFile";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { handleErrorWithToast } from "../../helpers/toastMessage/toastMessage";
import { loginAction } from "../../redux/action/authentication/authenticationAction";
import onboadingbg from "../../assets/onboardingtopbg-img.png";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { yupResolver } from "@hookform/resolvers/yup";

const getSteps = () => {
  return ["Register", "Create Profile", "Link Accounts", "SetupFiles"];
};

const initialValue = {
  username: "",
  email: "",
  password: "",
  region: "",
  confirm_password: "",
  google_service: false,
  extension_service: false,
  whatsapp_service: true,
  dropbox_service: false,
};

function getStepContent(step: number, isloading: boolean, country?: string) {
  switch (step) {
    case 0:
      return <Register country={country} />;
    case 1:
      return <CreateProfile />;
    case 2:
      return <LinkAccount country={country} />;
    case 3:
      return <SetupFile isloading={isloading} />;
    default:
      return <div>unknown step</div>;
  }
}

export const projectValidationSchema = [
  object({
    username: string().required("username required"),
    email: string().email().required("email required"),
    password: string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
      )
      .required(),
    confirm_password: string()
      .oneOf([ref("password"), null], "Passwords must match")
      .required(),
  }),

  object({
    google_service: boolean(),
    extension_service: boolean(),
    whatsapp_service: boolean(),
    dropbox_service: boolean(),
  }).test("myCustomTest", {}, (obj) => {
    if (
      obj.whatsapp_service ||
      obj.dropbox_service ||
      obj.extension_service ||
      obj.google_service
    ) {
      return true;
    }

    return new ValidationError(
      "Please check one checkbox",
      null,
      "myCustomFieldName",
    );
  }),
];

const Onboarding = () => {
  const [isloading, setIsLoading] = useState(false);
  const Base_URl = import.meta.env.VITE_APP_BASE_URL;
  let localStep: string | null | number = localStorage.getItem("activeStep");

  localStep = localStep === null ? 0 : Number(localStep);

  let localvalidation: string | null | number =
    localStorage.getItem("validationStep");

  localvalidation = localvalidation === null ? 0 : Number(localvalidation);

  const [activeStep, setActiveStep] = useState(localStep);

  const [validationStep, setValidationStep] = useState(localvalidation);

  useEffect(() => {
    let localStep: string | null | number = localStorage.getItem("activeStep");

    let localvalidation: string | null | number =
      localStorage.getItem("validationStep");

    if (localStep == null && localvalidation == null) {
      localStorage.setItem("activeStep", `${0}`);
      localStorage.setItem("validationStep", `${0}`);
    }
  }, []);

  const islocalData = JSON.parse(localStorage.getItem("userData") || "null");

  const methods = useForm({
    defaultValues: islocalData ? islocalData : initialValue,
    resolver: yupResolver(projectValidationSchema[validationStep]),
  });

  const steps = getSteps();

  const isStepFalied = () => {
    return Boolean(Object.keys(methods.formState.errors).length);
  };

  const dispatch = useAppDispatch();
  const handleNext = (value: any) => {
    // store form value
    localStorage.setItem("userData", JSON.stringify(value));

    if (activeStep === steps.length - 1) {
      const userToken = JSON.parse(localStorage.getItem("tempUser") || "null");
      //clear all localStorage item
      localStorage.removeItem("activeStep");
      localStorage.removeItem("userData");
      localStorage.removeItem("validationStep");
      localStorage.removeItem("tempUser");
      localStorage.removeItem("googleToken");
      setIsLoading(true);

      const loginData = {
        username: value.email.trim().toLowerCase(),
        password: value.password,
      };

      dispatch(loginAction(loginData) as any);
    } else {
      if (activeStep == 0 && validationStep == 0) {
        const formData = {
          username: value.username.trim().toLowerCase(),
          email: value.email.trim().toLowerCase(),
          hashed_password: value.password,
          region: value.region,
        };

        handleOnboardingSignup(formData);
        return;
      }

      // store next step
      localStorage.setItem("activeStep", `${activeStep + 1}`);

      const localStep = localStorage.getItem("activeStep");

      setActiveStep(Number(localStep));

      if (validationStep <= projectValidationSchema.length - 1) {
        localStorage.setItem("validationStep", JSON.stringify(1));
        const validStep = localStorage.getItem("validationStep");
        setValidationStep(Number(validStep));
      }
    }
  };

  async function handleOnboardingSignup(value: any) {
    const loginData = {
      username: value.email.trim().toLowerCase(),
      password: value.hashed_password,
    };

    try {
      const res = await axios.post(`${Base_URl}/auth/signup`, value);
      if (res.status == 200) {
        retriveToken(loginData);
        localStorage.setItem("activeStep", `${1}`);
        localStorage.setItem("validationStep", `${1}`);

        const localStep = localStorage.getItem("activeStep");

        setActiveStep(Number(localStep));

        const validStep = localStorage.getItem("validationStep");
        setValidationStep(Number(validStep));
      }
    } catch (error) {
      handleErrorWithToast(error);
    }
  }

  async function retriveToken(value: any) {
    const formData = new FormData();
    Object.keys(value).forEach((ele) => formData.append(ele, value[ele]));
    try {
      const res = await axios.post(`${Base_URl}/auth/token`, formData);

      localStorage.setItem("tempUser", JSON.stringify(res.data));
      localStorage.setItem(
        "googleToken",
        JSON.stringify(res.data.access_token),
      );
    } catch (error) {
      handleErrorWithToast(error);
    }
  }

  const handleBack = () => {
    localStorage.setItem("activeStep", `${activeStep - 1}`);
    const backstep = localStorage.getItem("activeStep");
    setActiveStep(Number(backstep));

    if (Number(backstep) == 0) {
      localStorage.setItem("validationStep", `${0}`);
    } else if (Number(backstep) == 1) {
      localStorage.setItem("validationStep", `${1}`);
    }
    const backvalidstep = localStorage.getItem("validationStep");
    setValidationStep(Number(backvalidstep));
  };

  const [country, setCountry] = useState();
  useEffect(() => {
    async function fetchCountry() {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();

        const userIPAddress = data.ip;

        if (userIPAddress) {
          try {
            const response = await fetch(
              `http://ipinfo.io/${userIPAddress}/json`,
            );
            const data = await response.json();
            setCountry(data.country);
          } catch (error) {
            console.error("Error fetching country:", error);
            setCountry(undefined);
          }
        }
      } catch (error) {
        console.error("Error fetching IP address:", error);
        setCountry(undefined);
      }
    }

    fetchCountry();
  }, []);

  return (
    <div className="onboarding-main">
      <ToastContainer autoClose={1000} />
      <div className="onboarding-main-top">
        <div className="onboarding-main-top-header">
          <h1>Connect. Organize. Search</h1>
          <p>Create your account below and get started with easio</p>
        </div>
        <img src={onboadingbg} alt="img" />
      </div>
      <div className="onboarding-main-bottom"></div>
      <div className=" onboarding-main-middle">
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((step, index) => {
              const labelProps = { error: false };
              if (isStepFalied() && activeStep == index) {
                labelProps.error = true;
              }

              return (
                <Step key={step}>
                  <StepLabel {...labelProps}>{step}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <FormProvider {...methods}>
            <form id="hook-form" onSubmit={methods.handleSubmit(handleNext)}>
              {getStepContent(activeStep, isloading, country)}
            </form>
            <div className="onboardingRegBtnDiv">
              <button onClick={handleBack} disabled={activeStep === 0}>
                Previous Step
              </button>
              <button type="submit" form="hook-form">
                {activeStep === steps.length - 1 ? "Finish" : "Next Step"}
              </button>
            </div>
          </FormProvider>
        </Box>
      </div>
    </div>
  );
};

export default Onboarding;
