import {
  GOOGLE_AUTH,
  GOOGLE_URL,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  SIGNUP_FAIL,
  SIGNUP_SUCCESS,
} from "../../action/authentication/authenticationType";

const token = localStorage.getItem("user");
const user: any = token ? JSON.parse(token) : undefined;

const initialState = user
  ? { isLoggedIn: true, user, googleurl: "" }
  : { isLoggedIn: false, user: null, googleurl: "" };

interface payloadType {
  isLoggedIn: boolean;
  user?: any;
  googleUrl: string;
}

interface authenticationReducer {
  type:
  | "SIGNUP_SUCCESS"
  | "SIGNUP_FAIL"
  | "LOGIN_SUCCESS"
  | "LOGIN_FAIL"
  | "LOGOUT"
  | "GOOGLE_AUTH"
  | "GOOGLE_URL";
  payload: payloadType;
}

const authenticationReducer = (
  state = initialState,
  { type, payload }: authenticationReducer,
) => {
  switch (type) {
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };

    case SIGNUP_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload,
      };

    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };

    case GOOGLE_AUTH:
      return {
        ...state,
        isLoggedIn: true,
        user: payload,
      };

    case GOOGLE_URL:
      return {
        ...state,
        googleUrl: payload,
      };

    case LOGOUT:
      return {
        isLoggedIn: false,
        user: null,
      };

    default:
      return state;
  }
};

export default authenticationReducer;
