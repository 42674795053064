import {
  handleErrorWithToast,
  handleToastMsg,
} from "../../helpers/toastMessage/toastMessage";
import {
  identifierServiceConnet,
  revmoveIdentifierServiceConnet,
} from "../../helpers/localstorage/localStorage";
import { useNavigate, useSearchParams } from "react-router-dom";

import ExtensionIcon from "../../assets/chromeExtn.png";
import WhatsApp from "./sub/whatsapp";
import WhatsupIcon from "../../assets/logo-whatsapp.png";
import axios from "axios";
import driveIcon from "../../assets/google-drive.png";
import dropboximg from "../../assets/dropbox.png";
import { useEffect } from "react";
import useFetch from "../../hooks/useFetch";

const ExtensionStoreURl = import.meta.env.VITE_APP_EXTENSION_STORE_URL;
const LinkAccount = (props: any) => {
  const { country } = props;
  let localUser = JSON.parse(localStorage.getItem("userData") || `{}`);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const Base_URl = import.meta.env.VITE_APP_BASE_URL;

  const token = JSON.parse(localStorage.getItem("googleToken") || "{}");

  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = useFetch(`${Base_URl}/google/get_auth_url/signup`, config);

  const {
    google_service,
    extension_service,
    whatsapp_service,
    dropbox_service,
  } = localUser;

  useEffect(() => {
    const service = localStorage.getItem("service");
    const state = searchParams.get("state");
    const code = searchParams.get("code");
    const scope = searchParams.get("scope");

    if (scope && code && state && service === "google") {
      const queryData: {
        state: string;
        code: string;
        scope: string;
        redirect_path: string;
      } = {
        state,
        code,
        scope,
        redirect_path: "signup",
      };
      saveGoogleToken(queryData);
      revmoveIdentifierServiceConnet("service");
      navigate("/signup");
    }
  }, []);

  async function saveGoogleToken(queryData: any) {
    try {
      const res = await axios.get(`${Base_URl}/google/store_token`, {
        params: {
          state: queryData.state,
          code: queryData.code,
          scope: queryData.scope,
          redirect_path: queryData.redirect_path,
        },
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      await axios.get(`${Base_URl}/google/import_gdrive`, config);

      handleToastMsg(res.data.status);
    } catch (error) {
      handleErrorWithToast(error);
    }
  }

  useEffect(() => {
    const state = searchParams.get("dropbox_auth");
    const service = localStorage.getItem("service");

    if (state && service === "dropbox") {
      handleToastMsg("dropbox successfully connected");
      revmoveIdentifierServiceConnet("service");
      navigate("/signup");
    }
  }, []);

  return (
    <>
      <div className="btn-service">
        {extension_service && (
          <a
            href={`${ExtensionStoreURl}`}
            target="_blank"
            rel="noopener noreferrer"
            className="service-btn-text"
          >
            <div>
              <img src={ExtensionIcon} alt="" />
              <span>Extension</span>
            </div>
          </a>
        )}

        {google_service && (
          <a href={data ? data : ""} className="service-btn-text">
            <div onClick={() => identifierServiceConnet("google")}>
              <img src={driveIcon} alt="" />
              <span>GOOGLE</span>
            </div>
          </a>
        )}

        {whatsapp_service && <WhatsApp country={country} />}

        {dropbox_service && (
          <a
            className="service-btn-text"
            href={`${Base_URl}/dropbox/get_auth_url/signup`}
          >
            <div onClick={() => identifierServiceConnet("dropbox")}>
              <img src={dropboximg} alt="" />
              <span>DropBox</span>
            </div>
          </a>
        )}
      </div>
    </>
  );
};

export default LinkAccount;
