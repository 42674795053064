import {
  SET_DATA,
  SET_KEY,
  SET_LOADING,
  SET_SORT,
} from "../../action/drawerSearch/drawerSearchTypes";

const initialState: {
  key: string;
  sort: string;
  data: any;
  loading: boolean;
} = {
  data: undefined,
  sort: "desc",
  key: "",
  loading: false,
};

type drawerSearch_reducerAction = {
  type: "SET_DATA" | "SET_KEY" | "SET_LOADING" | "SET_SORT";

  payload: any;
};

const drawerSearch_reducer = (
  state = initialState,
  { type, payload }: drawerSearch_reducerAction,
) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        load: payload,
      };

    case SET_KEY:
      return {
        ...state,
        key: payload,
      };

    case SET_SORT:
      return {
        ...state,
        sort: payload,
      };
    case SET_DATA:
      return {
        ...state,
        data: payload,
      };

    default:
      return state;
  }
};

export default drawerSearch_reducer;
