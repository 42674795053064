import {
  FILTER_NOTES,
  FILTER_ATTACHMENT,
  FILTER_LOCATION,
  FILTER_LINK,
  FILTER_EMAIL,
  FILTER_URL_NOTE,
  FILTER_CLEAR,
} from "./../../action/navigationFilter/navigationFilterType";

interface AddFileToggleReducerType {
  type: string;
  payload: string;
}

const initialState: { filter_tab: string } = { filter_tab: "" };

const navigationFilterReducer = (
  state = initialState,
  { type, payload }: AddFileToggleReducerType,
) => {
  switch (type) {
    case FILTER_NOTES:
      return { filter_tab: payload };

    case FILTER_ATTACHMENT:
      return { filter_tab: payload };

    case FILTER_LOCATION:
      return { filter_tab: payload };

    case FILTER_LINK:
      return { filter_tab: payload };

    case FILTER_EMAIL:
      return { filter_tab: payload };

    case FILTER_URL_NOTE:
      return { filter_tab: payload };

    case FILTER_CLEAR:
      return { filter_tab: payload };

    default:
      return state;
  }
};

export default navigationFilterReducer;
