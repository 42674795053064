import "./styles.css";

import { Outlet, useLocation } from "react-router-dom";

import DeskNav from "../../components/header/DeskNav";
import Profile_Detail from "../../components/userDetail/profile_detail/Profile_Detail";
import SettingsSidebar from "./sidebar";
import { Toolbar } from "./toolbar";

const Settings: React.FC<any> = () => {
  const Location = useLocation();

  return (
    <>
      <DeskNav />
      <div className="">
        <Toolbar />
        <div className="settings-container">
          <SettingsSidebar />
          <Outlet></Outlet>
          {Location.pathname === "/settings" && <Profile_Detail />}
        </div>
      </div>
    </>
  );
};

Settings.displayName = "Settings";
export default Settings;
