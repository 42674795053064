import clsx from "clsx";

export const MemberList = ({ members }: any) => {
  const vmembers = [
    {
      name: "Filip",
    },
    {
      name: "Shiv",
    },
    {
      name: "San",
    },
    {
      name: "Filip",
    },
    {
      name: "Shiv",
    },
    {
      name: "San",
    },
    {
      name: "Filip",
    },
    {
      name: "Shiv",
    },
    {
      name: "San",
    },
    {
      name: "Filip",
    },
    {
      name: "Shiv",
    },
    {
      name: "San",
    },
    {
      name: "Filip",
    },
    {
      name: "Shiv",
    },
    {
      name: "San",
    },
    {
      name: "Filip",
    },
    {
      name: "Shiv",
    },
    {
      name: "San",
    },

    {
      name: "Filip",
    },
    {
      name: "Shiv",
    },
    {
      name: "San",
    },
    {
      name: "Filip",
    },
    {
      name: "Shiv",
    },
    {
      name: "San",
    },
    {
      name: "Filip",
    },
    {
      name: "Shiv",
    },
    {
      name: "San",
    },
  ];
  return (
    <div className="list-container">
      <div className="list-title">Team Member</div>
      <div className="list-wrap">
        {vmembers.map((member, key) => {
          return (
            <div
              key={`member${key}`}
              className={clsx("list-item", key === 0 && "active")}
            >
              {member.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};
