import React from "react";
import "./Loader.css";

import loadernew from "../../assets/lodernew.svg";

const Loader: React.FC = () => {
  return (
    <div className="loderMain">
      <img className="loder" src={loadernew} alt="loder.." />
    </div>
  );
};

export default Loader;
