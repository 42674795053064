import { HttpClient } from "../helpers/httpClient";

const PATH = {
  google_url: "/google/get_auth_url",
  store_token: "/google/store_token",
  gdrive_import: "/google/import_gdrive",
};

interface queryDataType {
  state: string;
  code: string;
  scope: string;
  redirect_path?: string;
}

class GoogleService {
  public static get_auth_url(url: string) {
    return HttpClient.get(`${PATH.google_url}/${url} `);
  }

  public static store_token(queryData: queryDataType) {
    return HttpClient.get(`${PATH.store_token}`, {
      params: {
        state: queryData.state,
        code: queryData.code,
        scope: queryData.scope,
        redirect_path: queryData.redirect_path,
      },
    });
  }

  public static drive_import() {
    return HttpClient.get(`${PATH.gdrive_import}`);
  }
}

export default GoogleService;
