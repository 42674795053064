import {
  ALL_FILES_PAGE,
  GOOGLE_PAGE,
  WHATSAPP_PAGE,
  DROPBOX_PAGE,
  MAIL_PAGE,
  EXTENSION_PAGE,
  CALENDER_PAGE,
  GALLERY_PAGE,
  CLEAR_PAGE,
} from "../../action/reviewPage/reviewPageType";

const initialState = {
  active_page: "",
};

interface actionType {
  type:
    | "ALL_FILES_PAGE"
    | "GOOGLE_PAGE"
    | "WHATSAPP_PAGE"
    | "DROPBOX_PAGE"
    | "MAIL_PAGE"
    | "EXTENSION_PAGE"
    | "CALENDER_PAGE"
    | "GALLERY_PAGE"
    | "CLEAR_PAGE";
  payload: string;
}

const ReviewPageReducer = (
  state = initialState,
  { type, payload }: actionType,
) => {
  switch (type) {
    case ALL_FILES_PAGE:
      return {
        active_page: payload,
      };
    case GOOGLE_PAGE:
      return {
        active_page: payload,
      };
    case WHATSAPP_PAGE:
      return {
        active_page: payload,
      };
    case DROPBOX_PAGE:
      return {
        active_page: payload,
      };
    case MAIL_PAGE:
      return {
        active_page: payload,
      };
    case EXTENSION_PAGE:
      return {
        active_page: payload,
      };
    case CALENDER_PAGE:
      return {
        active_page: payload,
      };
    case GALLERY_PAGE:
      return {
        active_page: payload,
      };
    case CLEAR_PAGE:
      return {
        active_page: payload,
      };
    default:
      return state;
  }
};

export default ReviewPageReducer;
