import {
  CREATE_FOLDER,
  DELETE_FOLDER,
  MOVE_FOLDER,
} from "./../../action/folderAction/folderType";

const initialState: {
  folderStatus: {};
  move_folder_status: {};
  delete_folder_status: {};
} = {
  folderStatus: {},
  move_folder_status: {},
  delete_folder_status: {},
};

export interface folderReducerType {
  type: "CREATE_FOLDER" | "DELETE_FOLDER" | "MOVE_FOLDER";
  payload: any;
}

const folderReducer = (
  state = initialState,
  { type, payload }: folderReducerType,
) => {
  switch (type) {
    case CREATE_FOLDER:
      return {
        ...state,
        folderStatus: payload,
      };

    case MOVE_FOLDER:
      return {
        ...state,
        move_folder_status: payload,
      };

    case DELETE_FOLDER:
      return {
        ...state,
        delete_folder_status: payload,
      };

    default:
      return state;
  }
};

export default folderReducer;
