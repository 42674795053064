import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import AuthGuard from "./AuthGuard";
import Guard from "./Guard";
import Loader from "../components/loader/Loader";
import NavLayout from "../page/NavLayout/NavLayout";
import PrivateRouteList from "./PrivateRouteList";
import { RouteInterface } from "../interface/RouteInterface";
import authRoute from "./authRoute";

const AppRoutes: React.FC = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {authRoute.map((route: RouteInterface, index: number) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <Guard name={route.name}>
                  <route.component />
                </Guard>
              }
            />
          );
        })}

        {PrivateRouteList.map((route: RouteInterface, index: number) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <Suspense fallback={<Loader />}>
                  <AuthGuard name={route.name}>
                    <NavLayout>
                      <route.component />
                    </NavLayout>
                  </AuthGuard>
                </Suspense>
              }
            >
              {route.children?.map((subroute: RouteInterface, id: number) => {
                return (
                  <Route
                    key={id}
                    path={subroute.path}
                    element={
                      <Suspense fallback={<Loader />}>
                        <AuthGuard name={subroute.name}>
                          <subroute.component />
                        </AuthGuard>
                      </Suspense>
                    }
                  >
                    {subroute.subChildren?.map(
                      (subchild: RouteInterface, i: number) => {
                        return (
                          <Route
                            index={subchild.index ? subchild.index : false}
                            key={i}
                            path={subchild.path}
                            element={
                              <Suspense fallback={<Loader />}>
                                <AuthGuard name={subchild.name}>
                                  <subchild.component />
                                </AuthGuard>
                              </Suspense>
                            }
                          />
                        );
                      },
                    )}
                  </Route>
                );
              })}
            </Route>
          );
        })}
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
