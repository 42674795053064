import {
  EDITINFO,
  RESET_INFO,
  USERINFO,
} from "./../../action/userAction/userType";

export interface userReducerType {
  type: "EDITINFO" | "USERINFO" | "RESET_INFO";
  payload: any;
}

const initialState: {
  userInfo: any;
} = {
  userInfo: {},
};

const userReducer = (
  state = initialState,
  { type, payload }: userReducerType,
) => {
  switch (type) {
    case USERINFO:
      return {
        userInfo: payload,
      };
    case EDITINFO:
      return {
        userInfo: payload,
      };
    case RESET_INFO:
      return {
        userInfo: {},
      };
    default:
      return state;
  }
};

export default userReducer;
