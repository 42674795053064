import { HttpClient } from "../helpers/httpClient";
import { signupInterface } from "./../interface/signupInterface";

const PATH = {
  login: "/auth/token",
  signup: "/auth/signup",
  googleAuth: "/auth/login",
};

const SignupService = (payload: signupInterface) => {
  return HttpClient.post(`${PATH.signup}`, payload);
};

const LoginService = async (payload: any) => {
  const formData = new FormData();
  Object.keys(payload).forEach((ele) => formData.append(ele, payload[ele]));
  return await HttpClient.post(`${PATH.login}`, formData);
};

const GoogleLogin = (url: string) => {
  return HttpClient.get(PATH.googleAuth, { params: { redirect_uri: url } });
};

export { SignupService, LoginService, GoogleLogin };
