import "./styles.css";

import React, { useEffect, useState } from "react";

import { MemberDetail } from "./components/memberDetail";
import { MemberList } from "./components/memberList";
import { useAppDispatch } from "./../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";

const TeamSettings = () => {
  const { userInfo } = useAppSelector((state) => state.userReducer);
  const [user, setUser] = useState(userInfo);
  const dispatch = useAppDispatch();

  return (
    <div className="team-section">
      <MemberList />
      <MemberDetail />
    </div>
  );
};

export default TeamSettings;
