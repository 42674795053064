import {
  GOOGLE_AUTH,
  GOOGLE_URL,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  SIGNUP_FAIL,
  SIGNUP_SUCCESS,
} from "./authenticationType";
import {
  GoogleLogin,
  LoginService,
  SignupService,
} from "../../../services/authService";

import { AppDispatch } from "../../store/store";
import { RESET_INFO } from "../userAction/userType";
import { handleErrorWithToast } from "../../../helpers/toastMessage/toastMessage";
import jwt_decode from "jwt-decode";
import { loginInterface } from "../../../interface/loginInterface";
import { signupInterface } from "../../../interface/signupInterface";
import { storeUserData } from "../../../helpers/localstorage/localStorage";

export const SignupAction =
  (userData: signupInterface, handlesuccess: any) =>
    async (dispatch: AppDispatch) => {
      try {
        const res = await SignupService(userData);
        if (res.status === 200) {
          handlesuccess();
        }

        dispatch({
          type: SIGNUP_SUCCESS,
        });
        return Promise.resolve(res.data);
      } catch (error: any) {
        dispatch({
          type: SIGNUP_FAIL,
        });
        handleErrorWithToast(error);
        return Promise.reject(error);
      }
    };

export const loginAction =
  (userData: loginInterface) => async (dispatch: AppDispatch) => {
    try {
      const res = await LoginService(userData);
      const decodeValue: any = jwt_decode(res.data.access_token);
      const data = {
        access_token: res.data.access_token,
        root_folder: decodeValue["root_folder"],
        user_id: decodeValue["user_id"],
        email: decodeValue["sub"],
      };

      storeUserData("user", data);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: data,
      });
      return Promise.resolve(res);
    } catch (error: any) {
      dispatch({
        type: LOGIN_FAIL,
      });
      handleErrorWithToast(error);
      return Promise.reject(error);
    }
  };

export const googleAction = (queryData: any) => (dispatch: AppDispatch) => {
  try {
    const decodeValue: any = jwt_decode(queryData.access_token);
    const data = {
      ...queryData,
      root_folder: decodeValue["root_folder"],
      user_id: decodeValue["user_id"],
      email: decodeValue["sub"],
    };
    storeUserData("user", data);
    dispatch({
      type: GOOGLE_AUTH,
      payload: data,
    });
  } catch (error) {
    handleErrorWithToast(error);
  }
};

export const googleLoginUrl =
  (url: string) => async (dispatch: AppDispatch) => {
    try {
      const res = await GoogleLogin(url);
      dispatch({
        type: GOOGLE_URL,
        payload: res.data,
      });
      return Promise.resolve(res.data);
    } catch (error) {
      handleErrorWithToast(error);
      return Promise.reject(error);
    }
  };

export const logoutAction = () => (dispatch: AppDispatch) => {
  localStorage.removeItem("user");
  localStorage.removeItem("info");
  dispatch({
    type: RESET_INFO,
  });

  dispatch({
    type: LOGOUT,
  });
};
