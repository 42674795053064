import {
  CLEAR_BINDER,
  DELETE_BINDER_FILES,
  DELETE_DRAWER_FILES,
  DELETE_FILES,
  GET_DRAWER,
  GET_FILES,
  ROUTE_NAME,
  SEARCH_FILES,
  SELECT_BINDER,
  SET_ACTIVE_FILTER,
  SET_LOADING,
} from "./uploaded_files_list_type";
import { CLEAR_BINDER_DATA, CLEAR_SELECT_DATA } from "../filter/filterType";
import {
  handleErrorWithToast,
  handleToastMsg,
} from "../../../helpers/toastMessage/toastMessage";

import { AppDispatch } from "../../store/store";
import FolderService from "../../../services/folderService";
import Search_Service from "../../../services/searchService";
import _ from "lodash";
import { fileType } from "../../../interface/file-Interface";

const get_uploaded_files = (fileData: fileType[], type = "CURRENT_ALL") => {
  return {
    type: GET_FILES,
    payload: {
      fileData,
      type,
    },
  };
};

const set_active_filter = (filter: string) => {
  return {
    type: SET_ACTIVE_FILTER,
    payload: filter,
  };
};

const getDrawerFiles =
  (fileData: fileType[] | undefined) => async (dispatch: AppDispatch) => {
    dispatch({
      type: GET_DRAWER,
      payload: fileData,
    });
  };

const selectBinder =
  (binderData: fileType) => async (dispatch: AppDispatch) => {
    dispatch({
      type: CLEAR_SELECT_DATA,
    });

    dispatch({
      type: CLEAR_BINDER_DATA,
    });

    dispatch({
      type: CLEAR_BINDER,
    });

    dispatch({
      type: SELECT_BINDER,
      payload: binderData,
    });
  };

const delete_uploaded_files =
  (file_id: number) => async (dispatch: AppDispatch) => {
    try {
      //api call
      dispatch({
        type: DELETE_FILES,
        payload: file_id,
      });
    } catch (error) {
      handleErrorWithToast(error);
      return Promise.reject(error);
    }
  };

const delete_drawer_files =
  (file_id: number) => async (dispatch: AppDispatch) => {
    try {
      //api call
      await FolderService.DELETE_FOLDER({ object_id: file_id });

      // delete drawer
      dispatch({
        type: DELETE_DRAWER_FILES,
        payload: file_id,
      });

      // clear display data
      dispatch({
        type: CLEAR_SELECT_DATA,
      });

      //clear binder inside data

      dispatch({
        type: CLEAR_BINDER_DATA,
      });

      // clear binder
      dispatch({
        type: CLEAR_BINDER,
      });

      handleToastMsg("Drawer deleted Successfully");
    } catch (error) {
      handleErrorWithToast(error);
      return Promise.reject(error);
    }
  };

const delete_binder_files =
  (file_id: number) => async (dispatch: AppDispatch) => {
    try {
      //api call
      await FolderService.DELETE_FOLDER({ object_id: file_id });

      // clear display data
      dispatch({
        type: CLEAR_SELECT_DATA,
      });

      //clear binder inside data

      dispatch({
        type: CLEAR_BINDER_DATA,
      });

      // delete binder file
      dispatch({
        type: DELETE_BINDER_FILES,
        payload: file_id,
      });
      handleToastMsg("Binder deleted Successfully");
    } catch (error) {
      handleErrorWithToast(error);
      return Promise.reject(error);
    }
  };

const get_search_Files = (searchData: any) => async (dispatch: AppDispatch) => {
  try {
    const res = await Search_Service.searchItem(searchData);
    dispatch({
      type: SEARCH_FILES,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (error) {
    handleErrorWithToast(error);
    return Promise.reject(error);
  }
};

const myContentFilter = (data: any) => async (dispatch: AppDispatch) => {
  try {
    const res = await Search_Service.searchItem(data);
    dispatch({
      type: GET_FILES,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (error) {
    handleErrorWithToast(error);
    return Promise.reject(error);
  }
};

const clearBinder = () => {
  return {
    type: CLEAR_BINDER,
  };
};

const setloading = (status: boolean) => {
  return {
    type: SET_LOADING,
    payload: status,
  };
};

const setRoute = (name: string) => {
  return {
    type: ROUTE_NAME,
    payload: name,
  };
};

export {
  get_uploaded_files,
  delete_uploaded_files,
  get_search_Files,
  delete_drawer_files,
  selectBinder,
  delete_binder_files,
  myContentFilter,
  clearBinder,
  setloading,
  getDrawerFiles,
  setRoute,
  set_active_filter,
};
