import {
  REVIEW_CLOSED,
  REVIEW_OPEN,
} from "../../action/reviewAction/reviewActionType";

const initialState = {
  isReview: false,
};

interface reviewReducerType {
  type: "REVIEW_OPEN" | "REVIEW_CLOSED";
  payload: boolean;
}

const reviewReducer = (
  state = initialState,
  { type, payload }: reviewReducerType,
) => {
  switch (type) {
    case REVIEW_OPEN:
      return {
        ...state,
        isReview: payload,
      };
    case REVIEW_CLOSED:
      return {
        ...state,
        isReview: payload,
      };
    default:
      return state;
  }
};

export default reviewReducer;
