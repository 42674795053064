const screenToggle = (active = true, { type }: { type: string }) => {
  switch (type) {
    //disable screen toggle for the sidebar collapse/expand
    // case "Increase":
    //   return (active = !active);
    default:
      return true;
  }
};

export default screenToggle;
