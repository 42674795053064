import {
  NOTES,
  ATTACHMENTS,
  LOCATION,
  URLS,
  HIDE,
} from "./../../action/addFileToggle/addFileToggleType";

interface AddFileToggleReducerType {
  type: "NOTES" | "ATTACHMENTS" | "LOCATION" | "URLS" | "HIDE";
  payload: string;
}

const initialState: { addFile: string } = { addFile: "" };

const AddFileToggleReducer = (
  state = initialState,
  { type, payload }: AddFileToggleReducerType,
) => {
  switch (type) {
    case NOTES:
      return { addFile: payload };

    case ATTACHMENTS:
      return { addFile: payload };

    case LOCATION:
      return { addFile: payload };

    case URLS:
      return { addFile: payload };

    case HIDE:
      return { addFile: payload };
    default:
      return state;
  }
};

export default AddFileToggleReducer;
