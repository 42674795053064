import {
  ADD_FILE_RECOMMENDATION,
  ADD_MULTIPLE_FILE_RECOMMENDATION,
  DELETE_RECOMMENDATION_ACCEPT_FILE,
  DELETE_RECOMMENDATION_FILE,
  RECOMMENDATION_DRAWER_BINDER,
  RECOMMENDATION_FILE_ACCEPT,
  SET_LOADING,
} from "../../action/aiRecommendation/aiRecommendationType";

const initialState: {
  recommendation_data: any[];
  drawer: any[];
  binder: any[];
  fileList: any[];
  fileIds: any[];
  moveFileList: any[];
  recommendation: number | null;
  loading: boolean;
  tags: string[]
} = {
  recommendation_data: [],
  drawer: [],
  binder: [],
  fileList: [],
  fileIds: [],
  moveFileList: [],
  recommendation: null,
  loading: false,
  tags: []
};

type recommendation_reducerAction = {
  type:
  | "RECOMMENDATION_FILE_ACCEPT"
  | "RECOMMENDATION_DRAWER_BINDER"
  | "ADD_FILE_RECOMMENDATION"
  | "ADD_MULTIPLE_FILE_RECOMMENDATION"
  | "DELETE_RECOMMENDATION_FILE"
  | "DELETE_RECOMMENDATION_ACCEPT_FILE"
  | "SET_LOADING";

  payload: any;
};

const recommendation_reducer = (
  state = initialState,
  { type, payload }: recommendation_reducerAction,
) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        load: payload,
      };

    case RECOMMENDATION_FILE_ACCEPT:
      return {
        ...state,
        recommendation_data: payload,
      };

    case RECOMMENDATION_DRAWER_BINDER:
      return {
        ...state,
        drawer: payload.drawer,
        binder: payload.binder,
        fileList: payload.fileList,
        fileIds: payload.fileIds,
        recommendation: payload.recommendation_id,
        tags: payload.tags
      };

    case DELETE_RECOMMENDATION_FILE:
      const deleteData = state.fileList.filter((item) => item.id !== payload);

      return {
        ...state,
        fileList: deleteData,
      };

    case DELETE_RECOMMENDATION_ACCEPT_FILE:
      const deletefile = state.recommendation_data.filter(
        (item) => item.id !== payload,
      );

      return {
        ...state,
        recommendation_data: deletefile,
      };

    case ADD_FILE_RECOMMENDATION:
      let result = saveId(payload, state.moveFileList);
      return {
        ...state,
        moveFileList: result,
      };

    case ADD_MULTIPLE_FILE_RECOMMENDATION:
      return {
        ...state,
        moveFileList: payload,
      };

    default:
      return state;
  }
};

export default recommendation_reducer;

function saveId(id: number, state: any) {
  let arr: number[] = [...state];

  if (!arr.includes(id)) {
    arr.push(id);
    return arr;
  } else {
    let fileid = arr.indexOf(id);
    arr.splice(fileid, 1);
    return arr;
  }
}
