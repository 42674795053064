import "./profile_detail.css";

import React, { useEffect, useState } from "react";

import { editUserInfo } from "../../../redux/action/userAction/userAction";
import moment from "moment";
import { useAppDispatch } from "./../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";

const Profile_Detail = () => {
  const { userInfo } = useAppSelector((state) => state.userReducer);
  const [user, setUser] = useState(userInfo);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setUser(userInfo);
  }, [userInfo]);

  const handleInput = (e: any) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    user.dob = new Date(user.dob);
    dispatch(editUserInfo(user) as any);
  };

  return (
    <div className="userInfoWrapprofile">
      <h2>Basic Information</h2>
      <div className="profileInput-grid-wrap">
        <div className="userInfoName">
          <span>Full Name</span>

          <input
            type="text"
            name="first_name"
            value={user.first_name}
            onChange={handleInput}
            className="nameUserInfo"
          />
        </div>

        <div className="userInfoName">
          <span>UserName</span>
          <input
            type="text"
            name="username"
            value={user.username}
            onChange={handleInput}
            className="nameUserInfo"
          />
        </div>

        <div className="userInfoName">
          <span>Email-ID</span>
          <input
            type="text"
            name="email"
            value={user.email}
            onChange={handleInput}
            className="nameUserInfo"
          />
        </div>

        <div className="genderWrap">
          <div className="userInfoName">
            <span>Gender</span>

            <select
              name="gender"
              value={user.gender ? user.gender : ""}
              onChange={handleInput}
              className="nameUserInfo"
            >
              <option value="">N/A</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
          <div className="userInfoName">
            <span>Date of Birth</span>

            <input
              type="date"
              name="dob"
              value={user.dob ? moment(user.dob).format("YYYY-MM-DD") : ""}
              onChange={handleInput}
              className="nameUserInfo"
            />
          </div>
        </div>
      </div>
      <hr />
      <h2>Account details</h2>
      <div className="profileInput-grid-wrap">
        <div className="userInfoName">
          <span>Current Password</span>
          <input
            type="password"
            className="nameUserInfo"
            name="hashed_password"
            value={user.hashed_password}
            onChange={handleInput}
          />
        </div>
        <div className="userInfoName">
          <span>New Password</span>
          <input
            type="text"
            className="nameUserInfo"
            name="current_password"
            value={user.current_password}
            onChange={handleInput}
          />
        </div>
      </div>
      <div className="saveBtnDiProfile">
        <button onClick={handleSubmit}>Save Changes</button>
      </div>
    </div>
  );
};

export default Profile_Detail;
