import "./NavLayout.css";

import React from "react";

import SideNav from "../../components/sideNav/SideNav";
import { useAppSelector } from "../../hooks/useAppSelector";

const NavLayout: React.FC<any> = ({ children }) => {
  const active = useAppSelector(
    (state: { screenToggle: any }) => state.screenToggle,
  );

  return (
    <div className="container">
      <SideNav></SideNav>
      <div className={active ? "main active" : "main"}>{children}</div>
    </div>
  );
};

export default NavLayout;
