export const GET_FILES = "GET_FILES";
export const DELETE_FILES = "DELETE_FILES";
export const SEARCH_FILES = "SEARCH_FILES";
export const DELETE_DRAWER_FILES = "DELETE_DRAWER_FILES";
export const DELETE_BINDER_FILES = "DELETE_BINDER_FILES";
export const SELECT_BINDER = "SELECT_BINDER";
export const CLEAR_BINDER = "CLEAR_BINDER";
export const GET_DRAWER = "GET_DRAWER";
export const SET_LOADING = "SET_LOADING";
export const ROUTE_NAME = "ROUTE_NAME";
export const SET_ACTIVE_FILTER = "SET_ACTIVE_FILTER";
