import React, { Fragment, useState } from "react";
import "./Search_Dialog_Box.css";
import moment from "moment";
import { useAppSelector } from "../../hooks/useAppSelector";
import { RootState } from "../../redux/store/store";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { get_search_Files } from "../../redux/action/uploaded_files_list/uploaded_files_list_Action";

const initialState: {
  parent_id?: number;
  start_date?: string;
  end_date?: string;
  source?: string;
  name?: string;
  object_type?: string;
} = {};

const Search_Dialog_Box = (props: any) => {
  const [search, setSearch] = useState(initialState);
  const source = ["whatsapp", "easio", "gdrive", "dropbox"];
  const file_type = [
    "url_object",
    "file_object",
    "note_object",
    "url_note_object",
    "location_object",
  ];
  const { userInfo } = useAppSelector((state: RootState) => state.userReducer);
  const dispatch = useAppDispatch();
  const { setFilterActive } = props;
  const handleInput = (e: any) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  const onSubmitHandle = () => {
    if (Object.keys(search).length !== 0) {
      if (search.start_date && search.end_date) {
        let startTime = moment(search.start_date).format("YYYY-MM-DD");
        search.start_date = startTime + "T00:00:00.000Z";
        let endTime = moment(search.end_date).format("YYYY-MM-DD");
        search.end_date = endTime + "T23:59:59.000Z";
        search.parent_id = userInfo.root_folder;
        dispatch(get_search_Files(search) as any);
        setSearch({});
        setFilterActive((prev: boolean) => !prev);
        return;
      } else {
        dispatch(get_search_Files(search) as any);
        setSearch({});
        setFilterActive((prev: boolean) => !prev);
        return;
      }
    } else {
      setFilterActive((prev: boolean) => !prev);
      return;
    }
  };

  return (
    <div className="searchPopUp-div">
      {/* <div className="searchPopUp-input-wrap">
        <label>
          <input
            type="text"
            placeholder="Enter search by name"
            name="name"
            value={search.name}
            onChange={handleInput}
          />
        </label>
      </div> */}
      {/* <div className="binder-drwa-inpt-wrapp">
        <div className="drawer-div">Choose Drawer</div>
        <div className="binder-div">Choose Binder</div>
      </div> */}
      <div className="filetype-date-wrap">
        <div>
          <span>Source</span>
          <select
            name="source"
            id=""
            value={search.source}
            onChange={handleInput}
          >
            <option value="" disabled selected>
              select your source
            </option>
            {source.map((name, index) => (
              <Fragment key={index}>
                <option value={name}>{name}</option>
              </Fragment>
            ))}
          </select>
          {/* <div className="select-span">
            <span>Document</span>
            <span>Video</span>
            <span>Audio</span>
            <span>Image</span>
          </div> */}
        </div>
        <div>
          <span>File Type</span>
          <select
            name="object_type"
            id=""
            value={search.object_type}
            onChange={handleInput}
          >
            <option value="" disabled selected>
              select your sile type
            </option>
            {file_type.map((name, index) => (
              <Fragment key={index}>
                <option value={name}>{name}</option>
              </Fragment>
            ))}
          </select>
        </div>
      </div>

      <div className="date-lable-wrap">
        <div>
          <span>Date From</span>

          <label>
            <input
              type="date"
              placeholder="Enter search by name"
              name="start_date"
              value={search.start_date}
              onChange={handleInput}
            />
          </label>
        </div>
        <div>
          <span>Date To</span>
          <label>
            <input
              type="date"
              placeholder="Enter search by name"
              name="end_date"
              value={search.end_date}
              onChange={handleInput}
            />
          </label>
        </div>
      </div>

      <hr />
      <div className="three-btn-wrap">
        <button onClick={() => setFilterActive((prev: boolean) => !prev)}>
          Cancel
        </button>
        {/* <button>Create Filter</button> */}
        <button onClick={onSubmitHandle}>Search</button>
      </div>
    </div>
  );
};

export default Search_Dialog_Box;
