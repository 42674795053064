import {
  ADD_NOTES,
  ADD_LOCATION,
  ADD_URLS,
  DELETE_NOTES,
  DELETE_LOCATION,
} from "../../action/addFile/addFileType";

const initialState: {
  note: string;
  location: string;
  url: string;
  delete_note: string;
  delete_location: string;
} = {
  note: "",
  location: "",
  url: "",
  delete_note: "",
  delete_location: "",
};

interface AddFileReducerType {
  type:
    | "ADD_NOTES"
    | "ADD_LOCATION"
    | "ADD_URLS"
    | "DELETE_NOTES"
    | "DELETE_LOCATION";
  payload: string;
}

const addFileReducer = (
  state = initialState,
  { type, payload }: AddFileReducerType,
) => {
  switch (type) {
    case ADD_NOTES:
      return {
        ...state,
        note: payload,
      };
    case ADD_LOCATION:
      return {
        ...state,
        location: payload,
      };
    case ADD_URLS:
      return {
        ...state,
        url: payload,
      };
    case DELETE_NOTES:
      return {
        ...state,
        delete_note: payload,
      };
    case DELETE_LOCATION:
      return {
        ...state,
        delete_location: payload,
      };
    default:
      return state;
  }
};

export default addFileReducer;
