import { Navigate } from "react-router";
import React from "react";
import { useAppSelector } from "./../hooks/useAppSelector";

interface GuardType {
  children: React.ReactElement;
  redirectPath?: string;
  name?: string;
}

const Guard = ({ children, redirectPath = "/my-desk", name }: GuardType) => {
  const userState = useAppSelector((state: any) => state.authenticateReducer);
  const { isLoggedIn } = userState;
  if (name) {
    document.title = name;
  }
  if (isLoggedIn) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};

export default Guard;
