import {
  getRecommendationFile,
  setLoading as setRecommendationLoading,
} from "../../redux/action/aiRecommendation/aiRecommendationAction";

import { GetUserDetail } from "../../graphql/query/userQuery";
import { RootState } from "../../redux/store/store";
import { addUserInfo } from "../../redux/action/userAction/userAction";
import { getAllRecommendation } from "../../graphql/query/recommendation";
import { googleServiceAuthLink } from "../../redux/action/otherService/otherServiceAction";
import { useApollo } from "../../useApollo";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useEffect } from "react";
import { useSubscription } from "@apollo/client";

export const WsReduxWrapper: React.FC<any> = ({ children }) => {
  const { initClient } = useApollo();
  const { user } = useAppSelector(
    (state: RootState) => state.authenticateReducer,
  );
  useEffect(() => {
    if ((user as any)?.access_token) {
      initClient();
    }
  }, [user]);
  const {
    error,
    loading,
    data: userData,
  } = useSubscription(GetUserDetail, {
    skip: !(user as any)?.access_token,
  });
  const {
    error: recommendationError,
    loading: recommendationLoading,
    data: recommendationData,
  } = useSubscription(getAllRecommendation, {
    skip: !(user as any)?.access_token,
    variables: {
      user_id: (user as any)?.user_id
    }
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userData) {
      const contentObjects = userData.users[0];
      dispatch(googleServiceAuthLink("my-source") as any);
      dispatch(addUserInfo(contentObjects));
    }
  }, [userData]);

  useEffect(() => {
    if (recommendationData) {
      dispatch(getRecommendationFile(recommendationData) as any);
    }
  }, [recommendationData]);

  useEffect(() => {
    dispatch(setRecommendationLoading(recommendationLoading));
  }, [recommendationLoading]);

  return children;
};
