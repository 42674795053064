import "./DeskNav.css";

import { Link, useLocation, useParams } from "react-router-dom";
import React, { useState } from "react";

import BinderWithBtn from "../binderWithBtn/BinderWithBtn";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { RootState } from "../../redux/store/store";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Search_Dialog_Box from "../searchDialogBox/Search_Dialog_Box";
import { ToastContainer } from "react-toastify";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import profile from "../../assets/profile-img.png";
import screenToggleAction from "../../redux/action/screenToggleAction";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useDispatch } from "react-redux";

const DeskNav = () => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state: RootState) => state.userReducer);
  const [filterActive, setFilterActive] = useState(false);

  return (
    <div className="topbar">
      <div className="toggle-welcome-text-wrapper">
        <ToastContainer autoClose={2000} />
        <div className="toggle" onClick={() => dispatch(screenToggleAction)}>
          <MenuOutlinedIcon></MenuOutlinedIcon>
        </div>

        <div className="welcome-text">
          <span>
            Welcome{" "}
            {Object.keys(userInfo).length > 0 && (
              <>
                {typeof userInfo?.username !== "undefined" &&
                userInfo?.username ? (
                  <>
                    {userInfo?.username?.charAt(0).toUpperCase() +
                      userInfo?.username?.slice(1)}
                  </>
                ) : (
                  <>
                    {userInfo?.email.charAt(0).toUpperCase() +
                      userInfo?.email.slice(1)}
                  </>
                )}
              </>
            )}
          </span>
        </div>

        <div className="searchPopUpMainDiv">
          <div className="search">
            <label>
              <input type="text" placeholder="Search here" />
              {!filterActive ? (
                <TuneOutlinedIcon
                  className="ion-icon"
                  onClick={() => setFilterActive(() => !filterActive)}
                ></TuneOutlinedIcon>
              ) : (
                <CloseOutlinedIcon
                  className="ion-icon"
                  onClick={() => setFilterActive(() => !filterActive)}
                ></CloseOutlinedIcon>
              )}
            </label>
          </div>
          <div>
            {filterActive && (
              <Search_Dialog_Box
                setFilterActive={setFilterActive}
              ></Search_Dialog_Box>
            )}
          </div>
        </div>
      </div>

      <Link to="/profile">
        {userInfo.picture ? (
          <div className="user">
            <img src={userInfo.picture} alt="user-picture" />
          </div>
        ) : (
          <div className="user">
            <img src={profile} alt="profile" />
          </div>
        )}
      </Link>
    </div>
  );
};

export default DeskNav;
