import { HttpClient } from "./../helpers/httpClient";

const PATH = {
  search: "/search/items",
};

interface search_item_type {
  parent_id?: number;
  start_date?: string;
  end_date?: string;
  source?: string;
  name?: string;
  object_type?: string[];
  level?: number;
}

class Search_Service {
  public static searchItem(search_item: search_item_type) {
    return HttpClient.post(`${PATH.search}`, search_item);
  }
}

export default Search_Service;
