import { fileType } from "./../../../interface/file-Interface";
import {
  SELECT_NOTE,
  SELECT_EMAIL,
  SELECT_LOCATION,
  SELECT_LINK,
  SELECT_ATTACHMENT,
  SELECT_URL_NOTE,
  SELECT_DATA,
  DISPLAY_DATA,
  NOTE_DELETE,
  LOCATION_DELETE,
  ATTACHMENT_DELETE,
  LINK_DELETE,
  EMAIL_DELETE,
  URL_NOTE_DELETE,
  CLEAR_SELECT_DATA,
  GET_BINDER_DATA,
  DELETE_BINDER_DATA,
  CLEAR_BINDER_DATA,
} from "../../action/filter/filterType";

const initialState: {
  data: fileType[];
  selectData: {};
  binderData: fileType[];
} = {
  data: [],
  selectData: {},
  binderData: [],
};

interface filterType {
  type:
    | "SELECT_NOTE"
    | "SELECT_EMAIL"
    | "SELECT_LOCATION"
    | "SELECT_LINK"
    | "SELECT_ATTACHMENT"
    | "SELECT_URL_NOTE"
    | "SELECT_DATA"
    | "DISPLAY_DATA"
    | "NOTE_DELETE"
    | "LOCATION_DELETE"
    | "ATTACHMENT_DELETE"
    | "LINK_DELETE"
    | "EMAIL_DELETE"
    | "URL_NOTE_DELETE"
    | "CLEAR_SELECT_DATA"
    | "GET_BINDER_DATA"
    | "DELETE_BINDER_DATA"
    | "CLEAR_BINDER_DATA";
  payload: any;
}

function removeItem(state: typeof initialState, payload: number) {
  return state.data.filter((item: fileType) => item.id !== payload);
}

const filterReducer = (state = initialState, { type, payload }: filterType) => {
  switch (type) {
    case SELECT_NOTE:
      return {
        ...state,
        data: payload,
      };
    case GET_BINDER_DATA:
      return {
        ...state,
        binderData: payload,
      };

    case DELETE_BINDER_DATA:
      const newFiles = state.binderData.filter(
        (item: fileType) => item.id !== payload,
      );
      return {
        ...state,
        binderData: newFiles,
      };

    case SELECT_EMAIL:
      return {
        ...state,
        data: payload,
      };

    case SELECT_LOCATION:
      return {
        ...state,
        data: payload,
      };

    case SELECT_LINK:
      return {
        ...state,
        data: payload,
      };

    case SELECT_ATTACHMENT:
      return {
        ...state,
        data: payload,
      };

    case SELECT_URL_NOTE:
      return {
        ...state,
        data: payload,
      };

    case SELECT_DATA:
      return {
        ...state,
        selectData: payload,
      };

    case DISPLAY_DATA:
      return {
        ...state,
        data: payload,
      };
    case CLEAR_SELECT_DATA:
      return {
        ...state,
        selectData: {},
      };
    case CLEAR_BINDER_DATA:
      return {
        ...state,
        binderData: [],
      };

    case NOTE_DELETE:
      return {
        ...state,
        data: removeItem(state, payload),
      };

    case LOCATION_DELETE:
      return {
        ...state,
        data: removeItem(state, payload),
      };

    case ATTACHMENT_DELETE:
      return {
        ...state,
        data: removeItem(state, payload),
      };

    case LINK_DELETE:
      return {
        ...state,
        data: removeItem(state, payload),
      };

    case EMAIL_DELETE:
      return {
        ...state,
        data: removeItem(state, payload),
      };

    case URL_NOTE_DELETE:
      return {
        ...state,
        data: removeItem(state, payload),
      };

    default:
      return state;
  }
};

export default filterReducer;
