import Loader from "../components/loader/Loader";
import { Navigate } from "react-router";
import React from "react";
import { RootState } from "../redux/store/store";

import { useAppSelector } from "../hooks/useAppSelector";

interface AuthGuardType {
  children: React.ReactElement;
  redirectPath?: string;
  name?: string;
}

const AuthGuard = ({
  children,
  redirectPath = "/login",
  name,
}: AuthGuardType) => {
  const userState = useAppSelector(
    (state: RootState) => state.authenticateReducer,
  );
  const { user } = useAppSelector(
    (state: RootState) => state.authenticateReducer,
  );

  const { isLoggedIn } = userState;

  if (name) {
    document.title = name;
  }

  if (!isLoggedIn) {
    return <Navigate to={redirectPath} replace />;
  }

  if (!user) {
    return <Loader />;
  }

  return children;
};

export default AuthGuard;
