import { gql } from "@apollo/client";

export const addWhatsAppNumber = gql`
  mutation MyMutation($phone_number: bigint!, $user_id: Int!, $region: String) {
    update_users_by_pk(
      pk_columns: { id: $user_id }
      _set: { phone_number: $phone_number, whatsapp_active: true, region: $region }
    ) {
      id
    }
  }
`;
