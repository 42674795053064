import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Checkbox } from "@mui/material";
import { Controller } from "react-hook-form";
import ExtensionIcon from "../../assets/chromeExtn.png";
import WhatsupIcon from "../../assets/logo-whatsapp.png";
import driveIcon from "../../assets/google-drive.png";
import dropboximg from "../../assets/dropbox.png";
import { useFormContext } from "react-hook-form";

const CreateProfile = () => {
  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  // setValue('whatsapp_service', true);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.has("access_token")) {
      const googleToken = searchParams.get("access_token");

      const userInfo = {
        access_token: searchParams.get("access_token"),
        token_type: searchParams.get("token_type"),
        name: searchParams.get("name"),
        email: searchParams.get("email"),
        profile_picture: searchParams.get("profile_picture"),
      };

      localStorage.setItem("tempUser", JSON.stringify(userInfo));
      localStorage.setItem("googleToken", JSON.stringify(googleToken));

      navigate("/signup");
    }
  }, []);

  return (
    <div className="parent-div">
      <div>
        <h2>Create Profile</h2>
        <p className="intro">
          In order to give you the most accurate storage experience, we need to
          understand a few thing about your filling system
        </p>

        <h3>What cloud storages do you whish to link to Easio?</h3>

        <div>
          <div className="btn-service">
            <div>
              <Controller
                control={control}
                name="google_service"
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                )}
              />

              <img src={driveIcon} alt="" />
              <span>Google Drive</span>
            </div>

            <div>
              <Controller
                control={control}
                name="dropbox_service"
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                )}
              />

              <img src={dropboximg} alt="" />
              <span>DropBox</span>
            </div>

            <div>
              <Controller
                control={control}
                name="extension_service"
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                )}
              />
              <img src={ExtensionIcon} alt="" />
              <span>Extension</span>
            </div>

            <div>
              <Controller
                control={control}
                name="whatsapp_service"
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                )}
              />
              <img src={WhatsupIcon} alt="" />
              <span>WhatsUp</span>
            </div>
          </div>
          <span className="info">(*Select at least 1 and upto 3 option)</span>
        </div>
      </div>
    </div>
  );
};

export default CreateProfile;
